import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { Policies } from '@app/auth/auth-policies';
import { ErrorMessageType } from '@app/shared/components/error/error.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExecuteActionEvent, Message, SendMessageEvent, User } from '@progress/kendo-angular-conversational-ui';
import { TextAreaComponent } from '@progress/kendo-angular-inputs';
import { BlobService } from '@services/blob.service';
import { TwilioConversationsService } from '@services/twilio-conversations.service';
import { Message as TwilioMessage } from '@twilio/conversations';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-messaging',
  templateUrl: './patient-messaging.component.html',
  styleUrls: ['./patient-messaging.component.less'],
})
export class PatientMessagingComponent implements OnInit, OnDestroy {
  private unsub = new Subject<void>();
  loading = false;
  statusMessage = '';
  statusMessageType: ErrorMessageType;
  clientUser: User = null;
  users: User[] = [];
  messages: Message[] = [];
  sendPolicy = Policies.patientMessagingSend;

  @Input() displaySelection = true;

  @ViewChild('messageBoxInput', { static: false })
  messageBoxInput: TextAreaComponent;

  @ViewChild('uploadInput', { static: false }) inputFile: ElementRef;
  innerInputFocus = false;
  imagePreview: string;

  get connected() {
    return this.twilioService.initialized;
  }

  private _selectedImage: File;
  get selectedImage() {
    return this._selectedImage;
  }
  set selectedImage(file: File) {
    this._selectedImage = file;
    this.imagePreview = '';

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  constructor(
    private twilioService: TwilioConversationsService,
    private blobService: BlobService,
    @Optional() public activeModal?: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.twilioService.connectionState$
      .pipe(
        takeUntil(this.unsub),
        filter((state) => state != null)
      )
      .subscribe((state) => {
        switch (state) {
          case 'connecting':
            this.statusMessage = '';
            break;
          case 'connected':
            this.statusMessage = ''
            this.loading = false;
            break;
          case 'disconnecting':
            this.statusMessage = 'Disconnecting';
            this.statusMessageType = ErrorMessageType.Info;
            this.loading = false;
            break;
          case 'disconnected':
            this.statusMessage = 'Disconnected';
            this.statusMessageType = ErrorMessageType.Info;
            this.loading = false;
            break;
          case 'denied':
            this.statusMessage = 'Connection denied.';
            this.statusMessageType = ErrorMessageType.Error;
            this.loading = false;
            break;
          default:
            console.log(state);
            this.statusMessage = 'Unknown Error';
            this.statusMessageType = ErrorMessageType.Error;
            this.loading = false;
            break;
        }
      });

    this.twilioService.selectedConversationUpdated$.pipe(takeUntil(this.unsub)).subscribe(async (conversation) => {
      if (conversation) {
        const userIdentity = this.twilioService.getClientUserIdentity();
        const userParticipant = await conversation.getParticipantByIdentity(userIdentity);
        const userId = userParticipant.sid;
        this.clientUser = { id: userId };
        this.loading = true;
        await this.getMessages();
        this.loading = false;
      } else {
        this.clientUser = null;
      }
    });

    this.twilioService.conversationMessageAdded$.pipe(takeUntil(this.unsub)).subscribe(async (conversationSid) => {
      if (conversationSid && conversationSid === this.twilioService.selectedConversation?.sid) {
        await this.getMessages();
      }
    });
  }

  private async getMessages(): Promise<void> {
    const messages = this.twilioService.conversationMessages?.get(this.twilioService.selectedConversation?.sid);
    if (messages && messages.length >= 0) {
      this.messages = await Promise.all(messages?.map(async (message) => await this.mapTwilioMessageToKendo(message)));
    } else {
      this.messages = [];
    }
  }

  private async mapTwilioMessageToKendo(message: TwilioMessage): Promise<Message> {
    const attachments = await Promise.all(
      message.attachedMedia?.map(async (media) => ({
        contentType: media.contentType,
        content: await media.getContentTemporaryUrl(),
      }))
    );

    const user = (message.attributes as any).user;
    const automated = (message.attributes as any).automated;
    const sas = this.blobService.getReadOnlySAS();
    const kendoMessage: Message = {
      author: {
        id: message.participantSid,
        name: user ? user.fullName : automated ? 'System' : null,
        avatarUrl: user ? user.avatar + '?' + sas : automated ? '../../../assets/Avatars/avatar-placeholder.png' : null,
      },
      status: user ? user.fullName : automated ? 'System' : null,
      timestamp: message.dateCreated,
      text: message.body,
      attachmentLayout: 'list',
      attachments: attachments,
    };
    return kendoMessage;
  }

  async sendMessage(): Promise<void> {
    const messageBox = this.messageBoxInput;
    const newMessage = messageBox.value;
    const selectedImage = this.selectedImage;

    if (!newMessage) {
      return;
    }

    messageBox.value = null;
    messageBox.focus();
    this.selectedImage = null;
    this.twilioService.sendMessage(newMessage, selectedImage);
  }

  onInputFocus(): void {
    this.innerInputFocus = true;
  }

  onInputBlur(): void {
    this.innerInputFocus = false;
  }

  clearValue(): void {
    if (!this.innerInputFocus) {
      this.messageBoxInput.value = null;
      this.messageBoxInput.focus();
      this.innerInputFocus = true;
    }
  }

  // public onKeyDown(e: KeyboardEvent): void {
  //   const isEnter = e.keyCode === 13;

  //   if (!isEnter) {
  //     return;
  //   }

  //   const newLine = e.metaKey || e.ctrlKey;
  //   const enterOnly = !(e.shiftKey || e.metaKey || e.ctrlKey);

  //   if (enterOnly && this.innerInputFocus) {
  //     // e.preventDefault();
  //     // this.sendMessage();
  //   }
  //   if (newLine) {
  //     this.messageBoxInput.value += `\r\n`;
  //   }
  // }

  onFileSelect(event: Event): void {
    this.selectedImage = (event.target as HTMLInputElement).files[0];
    (event.target as HTMLInputElement).value = null;
    this.messageBoxInput.focus();
    this.innerInputFocus = true;
  }

  onAction(e: ExecuteActionEvent): void {
    if (e.action.type === 'reply') {
      this.inputFile.nativeElement.click();
    }
    this.messageBoxInput.focus();
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
    this.twilioService.selectedConversation = null;
    this.selectedImage = null;
  }
}
