import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router, RouterOutlet } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { PatientMessagingComponent } from '@app/messaging/patient-messaging/patient-messaging.component';
import { Allergy } from '@models/allergy';
import { NudgeReferenceType } from '@models/nudges/reference-type';
import { Patient } from '@models/patient';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService, MasterOverlayService } from '@services/actionpanel.service';
import { AllergiesService } from '@services/allergies/allergies.service';
import { BreakpointService } from '@services/breakpoint.service';
import { CurrentDataService } from '@services/currentData.service';
import { EventsService } from '@services/events.service';
import { PatientService } from '@services/patient.service';
import { TwilioConversationsService } from '@services/twilio-conversations.service';
import { UsersService } from '@services/users.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { VisitStatusDialogComponent } from '../../visit-status-dialog/visit-status-dialog.component';
import { AllergiesComponent } from './allergies/allergies.component';
import { CreateNudgesComponent } from './patient-nudges-tab/create-nudges/create-nudges.component';
import { AddPhotoComponent } from './patient-photos-tab/add-photo/add-photo.component';

@Component({
  selector: 'app-patient-tabs',
  templateUrl: './patient-tabs.component.html',
  styleUrls: ['./patient-tabs.component.less'],
})
export class PatientTabsComponent implements OnInit, OnDestroy {
  @ViewChild(RouterOutlet) routerOutlet: RouterOutlet;
  currentDate: Date;
  editNotesEnabled: boolean;
  editSocialHistoryEnabled: boolean;
  canShow: boolean;
  mobileView = true;
  patientAllergies: Allergy;
  actionPanelOpen = false;
  navLocked = false;
  private patientId: number;
  private patientWarningShown: boolean;
  private unsub: Subject<void> = new Subject<void>();

  get thePatient(): Patient {
    return this.patientService.patientPanelPatient;
  }
  set thePatient(value: Patient) {
    this.patientService.patientPanelPatient = value;
  }

  messagingPolicy = Policies.patientMessaging;

  private tabPolicies = new Map([
    ['Profile', Policies.patientProfile],
    ['Treatment Plan', Policies.patientTreatmentPlan],
    ['Chart', Policies.patientChart],
    ['Nudges', Policies.patientNudges],
    ['Account', Policies.patientAccount],
    ['Photos', Policies.patientPhotos],
    ['Communications', Policies.patientCommunications],
    ['Documents', Policies.patientDocuments],
  ]);

  private tabRouterLinks = new Map([
    ['Profile', 'patientprofiletab'],
    ['Treatment Plan', 'patienttreatmentplantab'],
    ['Chart', 'patientcharttab'],
    ['Nudges', 'patientnudgestab'],
    ['Account', 'patientaccounttab'],
    ['Photos', 'patientphotostab'],
    ['Communications', 'patientcommunicationtab'],
    ['Documents', 'patientdocumentstab'],
  ]);

  get authorizedTabLinks(): Map<string, string> {
    let authorized = new Map(
      [...this.tabRouterLinks].filter(([tab, route]) => this.authService.userSatisfiesPolicy(this.tabPolicies.get(tab)))
    );
    if (this.mobileView) {
      authorized = new Map([...authorized].filter(([tab, route]) => tab === 'Profile' || tab === 'Chart'));
    }
    return authorized;
  }

  constructor(
    private router: Router,
    public patientService: PatientService,
    private eventsService: EventsService,
    private masterOverlayService: MasterOverlayService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private userService: UsersService,
    public authService: AuthService,
    private actionPanelService: ActionPanelService,
    private route: ActivatedRoute,
    private allergiesService: AllergiesService,
    private currentDataService: CurrentDataService,
    private twilioService: TwilioConversationsService,
    private breakpointService: BreakpointService
  ) {}

  ngOnInit() {
    this.breakpointService.mobileBreakpoint$.pipe(takeUntil(this.unsub)).subscribe((mobileView) => {
      this.mobileView = mobileView;
    });

    this.router.events
      .pipe(
        takeUntil(this.unsub),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => this.checkForTabInUrl());

    this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    this.patientWarningShown = false;
    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.thePatient = patient;
      if (patient.rushPatientNote && !this.patientWarningShown) {
        this.showPatientWarning(patient);
      }
      this.patientWarningShown = true;
    });

    this.allergiesService
      .getAllergiesByPatientId(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        (allergy: Allergy) => {
          if (allergy) {
            this.patientAllergies = allergy;
          } else {
            this.patientAllergies = new Allergy();
            this.patientAllergies.allergyId = 0;
            this.patientAllergies.dateCreated = new Date();
            this.patientAllergies.dateUpdated = new Date();
            this.patientAllergies.patientId = this.patientId;
            this.patientAllergies.user = this.userService.loggedInUser;
          }
        },
        () => {
          console.log('Could not get allergies.');
        }
      );

    this.eventsService.currentDate.subscribe((date) => (this.currentDate = date));

    this.currentDataService.fetchCurrentData();

    setTimeout(() => (this.canShow = true), 0);
    this.currentDataService.preventUserFromNavigation$.pipe(takeUntil(this.unsub)).subscribe((navLocked) => {
      this.navLocked = navLocked;
    });

    this.checkForTabInUrl();
  }

  get currentTabLink(): string {
    return this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].children['action-panel']?.segments[3]
      ?.path;
  }

  private checkForTabInUrl() {
    if (!this.currentTabLink) {
      const tabLink = Array.from(this.authorizedTabLinks.values())[0];
      const urlTree = this.router.serializeUrl(this.router.createUrlTree([tabLink], { relativeTo: this.route }));
      this.router.navigateByUrl(urlTree);
    }
  }

  private showPatientWarning(patient: Patient) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Warning - Patient Has Important Note',
        content: patient.notesAndAlerts,
        confirmButtonText: 'Continue',
        showCancel: false,
      },
    });
    dialogRef.addPanelClass('patient-warning-box');
  }

  getBirthday(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  getDaysUntilBirthday() {
    return PatientService.getDaysUntilBirthday(this.thePatient);
  }

  getAge(date: Date) {
    return moment(new Date(Date.now())).diff(moment(date), 'years');
  }

  openAllergyModal() {
    const dialogRef = this.dialog.open(AllergiesComponent, {
      panelClass: 'allergy-dialog',
      width: '500px',
      data: {
        allergy: this.patientAllergies,
        patientId: this.patientId,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {});
  }

  onGetAvatarURI(avatar: string): string {
    return this.patientService.getAvatarURI(avatar);
  }

  saveAllergies(event: string) {
    this.patientAllergies.description = event;

    if (this.patientAllergies.allergyId === 0) {
      this.allergiesService
        .addAllergy(this.patientAllergies)
        .pipe(takeUntil(this.unsub))
        .subscribe(
          (allergy: Allergy) => {
            this.patientAllergies = allergy;
          },
          (error: HttpErrorResponse) => {
            // this.errorMessage = error.error.errors[0].fieldErrors[0];
          }
        );
    } else {
      this.allergiesService
        .updateAllergy(this.patientAllergies)
        .pipe(takeUntil(this.unsub))
        .subscribe(
          (allergy: Allergy) => {
            this.patientAllergies = allergy;
          },
          (error: HttpErrorResponse) => {
            // this.errorMessage = error.error.errors[0].fieldErrors[0];
          }
        );
    }
  }

  closePatientPanel() {
    this.canShow = false;
    this.masterOverlayService.masterOverlayState(false);
    const returnURL = this.router.url.slice(0, this.router.url.indexOf('('));
    this.router.navigate([returnURL, { outlets: { 'action-panel': null } }]);

    this.actionPanelService.actionPanelClosed.next();
  }

  bookAppointment() {
    this.closePatientPanel();
    let clickEvent = {
      isSelection: false,
      start: this.eventsService.selectedDate.value,
      end: this.eventsService.selectedDate.value,
      provider: null,
    };

    this.eventsService.setTempEvent(clickEvent);
    this.patientService.editedPatient = this.thePatient;
    this.router.navigate(['/schedule', { outlets: { 'action-panel': ['visit-details', '_', this.patientId] } }]);
  }

  patientCheckInClicked() {
    const dialogRef = this.dialog.open(VisitStatusDialogComponent, {
      width: '330px',
      data: { date: this.currentDate },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.eventsService.appointmentAdded.next();
    });
  }

  onSetAvatar() {
    const modalRef = this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });
    (modalRef.componentInstance as AddPhotoComponent).singleImageSelect = true;
    (modalRef.componentInstance as AddPhotoComponent).showGallery = true;
    if (this.thePatient.avatar) (modalRef.componentInstance as AddPhotoComponent).patient = this.thePatient;
    modalRef.result
      .then((result: PhotoMetaData) => {
        const avatarPath = result.filePath.split('?')[0];
        this.patientService.updatePatientAvatar(this.patientId, avatarPath).subscribe(() => {
          this.thePatient.avatar = result.filePath;
          this.patientService.thePatientHasBeenUpdated(this.thePatient);
        });
      })
      .catch(() => {
        // user exited the modal without selecting a photo
      });
  }

  patientPanelOpen(event: TransitionEvent) {
    if (event && event.propertyName && event.propertyName == 'width') {
      this.actionPanelService.actionPanelCompleteTransition(true);
      this.actionPanelOpen = true;
    }
  }

  openNudgeModal() {
    const dialogRef = this.dialog.open(CreateNudgesComponent, {
      panelClass: 'custom-dialog-container',
      width: '550px',
      data: {
        patientId: this.patientId,
        referenceType: NudgeReferenceType.Patient,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  async openChatModal() {
    try {
      await this.twilioService.selectPatientConversation(this.thePatient);
    } catch (error) {
      // Participant and proxy address pair is already in use
      if (error.body?.code === 50416) {
        this.dialog.open(GenericDialogComponent, {
          width: '350px',
          data: {
            title: 'Can not create a new conversation',
            content: 'A conversation already exists for a patient with the same mobile number.',
            confirmButtonText: 'OK',
            showCancel: false,
          },
        });
      } else {
        this.dialog.open(GenericDialogComponent, {
          width: '350px',
          data: {
            title: 'Can not open patient chat',
            content: error.message ?? 'An error occurred while trying to open the patient chat.',
            confirmButtonText: 'OK',
            showCancel: false,
          },
        });
      }
      return;
    }
    const modalRef = this.modalService.open(PatientMessagingComponent, {
      centered: true,
      windowClass: 'messaging-modal',
    });
    (modalRef.componentInstance as PatientMessagingComponent).displaySelection = false;
  }

  originalOrder(): number {
    return 0;
  }

  ngOnDestroy() {
    this.masterOverlayService.masterOverlayState(false);
    this.actionPanelOpen = false;
  }
}
