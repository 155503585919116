import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { PatientTypeheadComponent } from '@app/patients/patient-typehead/patient-typehead.component';
import { PatientSearchModalComponent } from '@app/shared/components/patient-search-modal/patient-search-modal.component';
import { Patient } from '@models/patient';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterOverlayService } from '@services/actionpanel.service';
import { BlobService } from '@services/blob.service';
import { PatientService } from '@services/patient.service';
import { TwilioConversationsService } from '@services/twilio-conversations.service';
import { Conversation } from '@twilio/conversations';
import * as moment from 'moment';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subject, forkJoin, of } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-conversations-sidebar',
  templateUrl: './conversations-sidebar.component.html',
  styleUrls: ['./conversations-sidebar.component.less'],
})
export class ConversationsSidebarComponent implements OnInit, OnDestroy {
  @ViewChild('scrollbar') scrollbar: NgScrollbar;
  @ViewChild('patientSearch') patientSearch: PatientTypeheadComponent;
  searchFormControl = new FormControl('');
  conversations: Conversation[] = [];
  filteredConversations: Conversation[] = [];
  conversationPatient = new Map<string, Patient>();
  loading = false;
  removePolicy = Policies.patientMessagingRemove;
  sendPolicy = Policies.patientMessagingSend;
  private unsub = new Subject<void>();

  get selectedConversation() {
    return this.twilioService.selectedConversation;
  }

  get readOnlySAS() {
    return this.blobService.getReadOnlySAS();
  }

  constructor(
    private twilioService: TwilioConversationsService,
    private blobService: BlobService,
    private modalService: NgbModal,
    private patientService: PatientService,
    private masterOverlayService: MasterOverlayService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loading = true;
    
    this.twilioService.conversations$
      .pipe(
        takeUntil(this.unsub),
        filter((conversations) => Boolean(conversations)),
        mergeMap((conversations) => {
          // Load patients for conversations
          return forkJoin(
            conversations.map((conversation) => {
              // Emit existing patient if already loaded
              const existingPatient = this.conversationPatient.get(conversation.sid);
              if (existingPatient) {
                return of(existingPatient).pipe(
                  map<Patient, [Conversation, Patient]>((patient) => [conversation, patient])
                );
              }
              const patientId = this.twilioService.parsePatientId(conversation);
              return this.patientService
                .getPatientById(patientId)
                .pipe(map<Patient, [Conversation, Patient]>((patient) => [conversation, patient]));
            })
          );
        })
      )
      .subscribe(async (result) => {
        this.conversations = [];
        this.conversationPatient.clear();
        result.forEach(([conversation, patient]) => {
          this.conversations.push(conversation);
          this.conversationPatient.set(conversation.sid, patient);
        });
        this.searchFormControl.setValue('');
        if (this.selectedConversation) {
          await this.scrollToConversation(this.selectedConversation);
        }
        this.loading = false;
      });

    this.searchFormControl.valueChanges.pipe(takeUntil(this.unsub)).subscribe((searchString) => {
      this.filteredConversations = this.filterConversations(searchString);
    });
  }

  private filterConversations(searchString: string) {
    const searchTerms = searchString.split(' ').map((term) => term.trim().toLowerCase());
    return this.conversations.filter((conversation) =>
      searchTerms.every((term) => conversation.friendlyName.toLowerCase().includes(term))
    );
  }

  onSelectionChange(change: MatSelectionListChange) {
    this.twilioService.selectedConversation = change.options[0]?.value;
  }

  async typeaheadOnSelect(event) {
    if (event.data) {
      await this.twilioService.selectPatientConversation(event.data);
      this.patientSearch.removeSearchTerm();
    }
  }

  addConversation() {
    const modalRef = this.modalService.open(PatientSearchModalComponent, { centered: true });
    modalRef.result.then(async (patient: Patient) => {
      if (patient) {
        this.loading = true;
        try {
          const fullPatient = await this.patientService.getPatientById(patient.patientId).toPromise();
          await this.twilioService.selectPatientConversation(fullPatient);
          await this.scrollToConversation(this.selectedConversation);
        } catch (error) {
          // Participant and proxy address pair is already in use
          if (error.body?.code === 50416) {
            const dialogRef = this.dialog.open(GenericDialogComponent, {
              width: '350px',
              data: {
                title: 'Can not create a new conversation',
                content: 'A conversation already exists for a patient with the same mobile number.',
                confirmButtonText: 'OK',
                showCancel: false,
              },
            });
            return;
          }
          throw error;
        } finally {
          this.loading = false;
        }
      }
    });
  }

  // For internal use. Will permanently delete to conversation. Button commented out in template.
  async deleteConversation(conversation: Conversation) {
    this.loading = true;
    await conversation.delete();
  }

  async removeConversation(conversation: Conversation) {
    await this.twilioService.setConversationArchiveStatus(conversation, true);
    if (conversation.sid === this.twilioService.selectedConversation?.sid) {
      this.twilioService.selectedConversation = null;
    }
  }

  getUnreadCount(conversation: Conversation): number {
    return this.twilioService.getUnreadCount(conversation);
  }

  getLatestMessage(conversation: Conversation): string {
    const lastMessage = this.twilioService.getLatestMessage(conversation);
    if (!lastMessage) return '';
    const fromName = lastMessage.author.includes('clinic') ? 'Clinic' : 'Patient';
    return `${fromName}: ${lastMessage.body}`;
  }

  getAllAutomatedStatus(conversation: Conversation): boolean {
    return this.twilioService.conversationAllAutomated.get(conversation.sid);
  }

  getArchivedStatus(conversation: Conversation): boolean {
    return this.twilioService.getConversationArchiveStatus(conversation);
  }

  goToPatientProfile(conversation: Conversation) {
    if (!conversation) return;
    const patient = this.conversationPatient.get(conversation.sid);
    this.patientService.patientPanelPatient = patient;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([
      '/patient-messaging',
      { outlets: { 'action-panel': ['patient', patient.patientId + '_' + 'patientprofiletab'] } },
    ]);
  }

  getPatient(conversation: Conversation): Patient {
    return this.conversationPatient.get(conversation.sid);
  }

  getAge(date: Date) {
    return moment(new Date(Date.now())).diff(moment(date), 'years');
  }

  private async scrollToConversation(conversation: Conversation) {
    if (conversation) {
      const element = document.getElementById(conversation.uniqueName);
      await this.scrollbar.scrollToElement(element);
    }
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
