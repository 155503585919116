import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PaymentMethod } from '@models/finance/payment-method';
import { environment } from '@environments/environment';
import { InvoicePayment } from '@models/finance/invoice-payment';
import { LoyaltyConverting } from '@models/finance/loyalty-convering';
import { InvoiceTransaction } from '@models/finance/invoice-transaction';
import { ResultModel } from '@models/result-model';
import { PaidCancellationInvoiceTransaction } from '@models/finance/paid-cancellation-invoice-transaction';

@Injectable({
  providedIn: 'root',
})
export class FinanceService {
  invoicePaid = new Subject();
  invoicePaid$ = this.invoicePaid.asObservable();

  constructor(private http: HttpClient) {}

  addTransactions(invoicePayment: InvoicePayment) {
    return this.http.post(`${environment.baseUrl}api/Finance/AddTransactions`, invoicePayment);
  }

  addTransaction(invoiceTransaction: InvoiceTransaction) {
    return this.http.post<ResultModel<InvoiceTransaction>>(
      `${environment.baseUrl}api/Finance/AddFinancialTransaction`,
      invoiceTransaction
    );
  }

  addPaidCancellationRefundTransaction(paidCancellationInvoiceTransaction: PaidCancellationInvoiceTransaction) {
    return this.http.post<ResultModel<InvoiceTransaction>>(
      `${environment.baseUrl}api/Finance/AddPaidCancellationFinancialTransaction`,
      paidCancellationInvoiceTransaction
    );
  }

  getPatientAvailableCredit(patientId: number): Observable<number> {
    return this.http.get<number>(`${environment.baseUrl}api/Finance/GetPatientAvailableCredit/${patientId}`);
  }

  updatePatientAvailableCredit(patientId: number, newAvailableCreditAmount: number): Observable<number> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<number>(
      `${environment.baseUrl}api/Finance/UpdatePatientAvailableCredit/${patientId}`,
      newAvailableCreditAmount,
      httpOptions
    );
  }

  getOutstandingAmount(invoiceId: number): Observable<number> {
    return this.http.get<number>(`${environment.baseUrl}api/Finance/GetOutstandingAmount/${invoiceId}`);
  }

  getPatientAvailableLoyaltyPoints(patientId: number): Observable<number> {
    return this.http.get<number>(`${environment.baseUrl}api/Finance/GetPatientAvailableLoyaltyPoints/${patientId}`);
  }

  getPatientAvailableLoyaltyPointsDollarValue(patientId: number): Observable<number> {
    return this.http.get<number>(
      `${environment.baseUrl}api/Finance/GetPatientAvailableLoyaltyPointsDollarValue/${patientId}`
    );
  }

  getInvoicePaidAmount(invoiceId: number): Observable<number> {
    return this.http.get<number>(`${environment.baseUrl}api/Finance/GetPaidAmount/${invoiceId}`);
  }

  getFinanceTransaction(financeTransactionId: number): Observable<InvoiceTransaction> {
    return this.http.get<InvoiceTransaction>(
      `${environment.baseUrl}api/Finance/GetFinanceTransaction/${financeTransactionId}`
    );
  }

  convertLoyaltyPointsToCredit(loyaltyConvertingModel: LoyaltyConverting) {
    return this.http.post(`${environment.baseUrl}api/Finance/ConvertLoyaltyPointsToCredit`, loyaltyConvertingModel);
  }
  getInvoiceTransactions(invoiceId: number): Observable<InvoiceTransaction[]> {
    return this.http.get<InvoiceTransaction[]>(`${environment.baseUrl}api/Finance/GetInvoiceTransactions/${invoiceId}`);
  }

  voidInvoiceTransaction(invoiceTransaction: InvoiceTransaction): Observable<InvoiceTransaction> {
    return this.http.post<InvoiceTransaction>(
      `${environment.baseUrl}api/Finance/VoidFinanceTransaction`,
      invoiceTransaction
    );
  }
}
