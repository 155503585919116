<div class="sidenav" [ngClass]="{ sideNavCollapsed: !sideNavExpanded }">
  <div class="d-flex h-100 flex-column">
    <div class="profile">
      <div
        kendoTooltip
        tooltipClass="em-tooltip"
        position="right"
        class="img-container"
        [ngClass]="{ 'mx-auto': !sideNavExpanded }"
      >
        <img
          class="img-avatar"
          [src]="loggedInUserAvatar"
          alt="User Avatar"
          title="Account"
          (click)="userAccountClick()"
        />
      </div>
      <!-- <div [ngClass]="{ inactive: !sideNavExpanded }" class="info">
        <h5 class="text-uppercase">{{ loggedInUserName }}</h5>
      </div> -->
    </div>
    <div class="navbar">
      <ul class="nav flex-column">
        <li class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/schedule">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <i class="far fa-home fa-lg" title="Schedule"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Schedule</span>
          </a>
        </li>
        <li class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/nudges">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon position-relative"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <div *ngIf="nudgeCounter" class="nav-link-badge position-absolute">{{ nudgeCounter }}</div>
              <i class="far fa-bell fa-lg" title="Nudges"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Nudges</span>
          </a>
        </li>
        <li *appAuthGuard="patientMessaging" class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/patient-messaging">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon position-relative"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <div *ngIf="patientMessageCount" class="nav-link-badge position-absolute">{{ patientMessageCount }}</div>
              <i class="far fa-comments-alt fa-lg" title="Patient Messaging"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Patient Messaging</span>
          </a>
        </li>
        <li *appAuthGuard="reviewsPolicy" class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/reviews">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon position-relative"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <div *ngIf="reviewNotificationsCounter" class="nav-link-badge position-absolute">
                {{ reviewNotificationsCounter }}
              </div>
              <i class="far fa-star fa-lg" title="Reviews"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Reviews</span>
          </a>
        </li>
        <li *appAuthGuard="galleryPolicy" class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/gallery">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <i class="far fa-images fa-lg" title="Gallery"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Gallery</span>
          </a>
        </li>
        <li *appAuthGuard="communicationsPolicy" class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/management/communication">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <i class="far fa-envelope-open-text fa-lg" title="Communications"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Communication</span>
          </a>
        </li>
        <li class="nav-item position-relative" *appAuthGuard="managementPolicy" routerLinkActive="active">
          <a
            class="nav-link"
            (pointerenter)="submenuMouseEnter(subMenuTrigger)"
            (pointerleave)="submenuMouseLeave(subMenuTrigger, $event)"
            (clickOutside)="subMenuTrigger.closeMenu()"
          >
            <span
              class="menu-icon"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <i class="far fa-cogs fa-lg"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Management</span>
          </a>
          <div #subMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="subMenu" class="sub-menu-trigger"></div>
          <mat-menu #subMenu="matMenu" [xPosition]="'after'" [hasBackdrop]="false" class="nav-sub-menu">
            <ul
              (pointerenter)="submenuMouseEnter(subMenuTrigger)"
              (pointerleave)="submenuMouseLeave(subMenuTrigger, $event)"
            >
              <li
                class="sub-menu-item p-2"
                routerLinkActive="active"
                *appAuthGuard="cataloguePolicy"
                routerLink="/management/catalogue"
              >
                <a routerLink="/management/catalogue">Catalogue</a>
              </li>
              <li class="sub-menu-item p-2" routerLinkActive="active" *appAuthGuard="formsPolicy">
                <a routerLink="/management/forms">eForms</a>
              </li>
              <li
                class="sub-menu-item p-2"
                routerLinkActive="active"
                *appAuthGuard="managementOrganizationPolicy"
                routerLink="/management/organization/clinics"
              >
                <a routerLink="/management/organization/clinics">Clinic</a>
              </li>
              <!--
              <li
                class="sub-menu-item"
                routerLinkActive="active"
                routerLink="/management/gift-cards"
              >
                Gift Cards
              </li>
              -->
            </ul>
          </mat-menu>
        </li>
        <li class="nav-item position-relative" *appAuthGuard="addressBookPolicy" routerLinkActive="active">
          <a class="nav-link" routerLink="/management/address-book/doctors">
            <span
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
            >
              <i class="far fa-address-book fa-lg" aria-hidden="true" title="Address Book"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Address Book</span>
          </a>
        </li>
        <li class="nav-item position-relative" routerLinkActive="active">
          <a class="nav-link" routerLink="/messages">
            <span
              class="menu-icon position-relative"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
            >
              <div *ngIf="messagingNotificationsCounter" class="nav-link-badge position-absolute">
                {{ messagingNotificationsCounter }}
              </div>
              <i class="far fa-envelope fa-lg" title="Clinic Messaging"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Clinic Messaging</span>
          </a>
        </li>
        <li class="nav-item position-relative" *appAuthGuard="reportsPolicy" routerLinkActive="active">
          <a class="nav-link" routerLink="/reports">
            <span
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
            >
              <i class="far fa-chart-bar fa-lg" aria-hidden="true" title="Reports"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Reports</span>
          </a>
        </li>
        <li class="nav-item position-relative" *appAuthGuard="powerReportsPolicy" routerLinkActive="active">
          <a class="nav-link" routerLink="/epower-reports">
            <span
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
            >
              <i class="far fa-chart-line fa-lg" aria-hidden="true" title="ePower Reports"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">ePower Reports</span>
          </a>
        </li>
        <li
          class="nav-item position-relative"
          routerLink="/patients-list"
          *appAuthGuard="patientListPolicy"
          routerLinkActive="active"
        >
          <a class="nav-link" routerLink="/patients-list">
            <span
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
            >
              <i class="far fa-users fa-lg" aria-hidden="true" title="Patients"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Patients</span>
          </a>
        </li>
      </ul>
      <!-- <div class="w-100">
        <hr />
        <app-quick-links></app-quick-links>
      </div> -->
    </div>

    <div
      class="expand-control nav-link"
      [ngClass]="{ sideNavCollapsed: !sideNavExpanded }"
      (click)="sideNavExpandClick()"
    >
      <i *ngIf="!sideNavExpanded" class="far fa-chevron-double-right fa-lg" aria-hidden="true" title="Expand"></i>
      <i
        *ngIf="sideNavExpanded"
        class="far fa-chevron-double-left fa-lg expanded"
        aria-hidden="true"
        title="Collapse"
      ></i>
    </div>

    <div class="m-2 logo-container">
      <a class="w-100" routerLink="/schedule">
        <img *ngIf="clinic?.logoUrl; else emilyLogo" class="w-100" [src]="clinic.logoUrl" alt="Clinic Logo" />
        <ng-template #emilyLogo>
          <div class="w-100" [inlineSVG]="'../../../../../../../assets/svg/emily-logo-final.svg'"></div>
        </ng-template>
      </a>
    </div>

    <div class="logout-link pl-2 py-2 white-font" (click)="logout()">
      <a data-test-id="logout-button">Log Out</a>
    </div>
  </div>
</div>
