import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { QuickAddPharmacyComponent } from '@app/management/address-book/actionpanel/quick-add-pharmacy/quick-add-pharmacy.component';
import { QuickAddPhysicianComponent } from '@app/management/address-book/actionpanel/quick-add-physician/quick-add-physician.component';
import { isNullOrUndefined, toTitleCase } from '@app/shared/helpers';
import { phoneNumberValidator } from '@app/shared/validators/phone-number.validator';
import { postalCodeValidator } from '@app/shared/validators/postal-code.validator';
import { Address } from '@models/address';
import { Doctor } from '@models/doctor';
import { Patient } from '@models/patient';
import { PatientStatus } from '@models/patient-status.enum';
import { Pharmacy } from '@models/pharmacy';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ClinicsService } from '@services/clinics.service';
import { DoctorsService } from '@services/doctors.service';
import { EventsService } from '@services/events.service';
import { FormatterService } from '@services/formatter.service';
import { MspCardSwipeService } from '@services/msp-card-swipe.service';
import { PatientService } from '@services/patient.service';
import { PharmaciesService } from '@services/pharmacies.service';
import { ValidationService } from '@services/validation.service';
import { CountryName, CountrySlug, RegionName, RegionSlug, allCountries } from 'country-region-data';
import { AsYouType, CountryCode, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from 'saturn-datepicker';

interface RegionData {
  name: RegionName;
  slug: RegionSlug;
}

interface CountryData {
  name: CountryName;
  slug: CountrySlug;
  regions: RegionData[];
}

@Component({
  selector: 'app-edit-patient-content',
  templateUrl: './edit-patient-content.component.html',
  styleUrls: ['./edit-patient-content.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'YYYY-MM-DD',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
})
export class EditPatientContentComponent implements OnInit, OnDestroy {
  private _forceNew: boolean = false;
  @Input() set forceNew(val: boolean) {
    this._forceNew = val;
    this.addOrEdit = 'Add';
    //this.showCardSwipe = true;
  }
  get forceNew() {
    return this._forceNew;
  }
  _currentPatient: Patient;
  @Input() set currentPatient(currentPatient) {
    if (currentPatient) {
      this._currentPatient = currentPatient;
      this.selectedPatient = currentPatient;
      this.doNotOverwritePatient = true;
      this.addOrEdit = 'Edit';
      this.isNew = false;
    }

    this.initForm();
    this.getExistPortalAccount(this.selectedPatient.patientId);
  }
  get currentPatient() {
    return this._currentPatient;
  }
  _viewModeDefault: boolean = false;
  @Input() set viewModeDefault(val: boolean) {
    this._viewModeDefault = val;
    this.isEditForm = !val;
    this.isShowEditButton = val;
  }
  get viewModeDefault() {
    return this._viewModeDefault;
  }
  @Output() cancelEditEvent = new EventEmitter<boolean>();
  @Output() updatedPatientEvent = new EventEmitter<number>();

  communicationPreferences: string[] = ['Email', 'Phone', 'Text', 'None'];
  addOrEdit = 'Add';
  isEditForm: boolean = true;
  isShowEditButton: boolean = false;
  minBirthdate: Date = new Date(1900, 0);
  maxBirthdate = new Date();
  showCardSwipe: boolean = false;
  doNotOverwritePatient: boolean = false;
  patientIdParam: string;
  patientFormGroup: FormGroup;
  isNew: boolean = true;
  cardSwipeError;
  selectedPatient: Patient;
  editedPatient: Patient;
  selectedAddress: Address;
  editedAddress: Address;
  theDoctors: Doctor[];
  thePharmacies: Pharmacy[];
  phoneNumberFormatter = new AsYouType();
  countries: CountryData[];
  selectedCountryRegions: RegionData[];
  unsub: Subject<void> = new Subject<void>();
  loading = false;
  errorMessage: string;
  patientStatusData = PatientStatus;
  statusEnumKeys = [];
  duplicateMspInfo: any = {
    patientId: '',
    patientName: '',
  };
  duplicateEmailInfo: any = {
    patientId: '',
    name: '',
  };
  developerPolicy = Policies.developer;

  constructor(
    private doctorService: DoctorsService,
    private pharmaciesService: PharmaciesService,
    private validationService: ValidationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private mspCardSwipeService: MspCardSwipeService,
    public patientService: PatientService,
    public formatterService: FormatterService,
    private eventsService: EventsService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private dialog: MatDialog,
    private clinicsService: ClinicsService
  ) {
    this.statusEnumKeys = Object.keys(this.patientStatusData).filter((f) => !isNaN(Number(f)));
    this.statusEnumKeys = this.statusEnumKeys.map(Number);
    this.countries = allCountries.map((country) => ({
      name: country[0],
      slug: country[1],
      regions: country[2].map((region) => ({ name: region[0], slug: region[1] })),
    }));
    const can = this.countries.find((country) => country.slug == 'CA');
    const usa = this.countries.find((country) => country.slug == 'US');
    const aus = this.countries.find((country) => country.slug == 'AU');
    this.countries.unshift(can, usa, aus);
  }

  async ngOnInit() {
    this.loading = true;

    if (this.currentPatient) {
      this.selectedPatient = this.currentPatient;
    } else {
      await this.initPatient(this.selectedPatient);
    }

    if (isNullOrUndefined(this.patientService.editedPatient)) {
      this.patientService.editedPatient = this.selectedPatient;
    }

    if (this.selectedPatient.patientId) {
      this.addOrEdit = 'Edit';
      this.isNew = false;
    }

    this.theDoctors = [];
    const doctorPromise = this.doctorService.getDoctors().toPromise();
    const pharmacyPromise = this.pharmaciesService.getPharmacies().toPromise();
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.patientIdParam = params['patid'] || params['patientId']; //this will need to be cleaned up in the route definitions once we decide on how this component is going to work in all the different spots - was originally implemented for single use
      if (this.patientIdParam && this.patientIdParam !== '_' && !this.forceNew) {
        this.patientService
          .getPatientById(this.patientIdParam)
          .pipe(takeUntil(this.unsub))
          .subscribe((snapshot) => {
            if (!this.doNotOverwritePatient) {
              const patient: Patient = snapshot as Patient;
              this.selectedPatient = patient;
              this.patientService.editedPatient = patient;
            }

            this.isNew = false;
            this.addOrEdit = 'Edit';
            this.initForm();
            this.getExistPortalAccount(this.selectedPatient.patientId);
          });
      } else {
        this.initForm();
      }
    });
    [this.theDoctors, this.thePharmacies] = await Promise.all([doctorPromise, pharmacyPromise]);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  // Currently unused, can be used when autofill is implemented
  // getDocName(doctor: any): string {
  //   let returnVal: string;
  //   if (typeof doctor === 'string') {
  //     if (doctor === '0') {
  //       returnVal = '';
  //     } else if (doctor === '-1') {
  //       returnVal = 'None';
  //     } else {
  //       const docId = parseInt(doctor);
  //       const match = this.theDoctors.find(doc => doc.doctorId === docId)
  //       returnVal = match? this.getDocName(match) : '';
  //     }
  //   } else {
  //     returnVal = doctor ? `${doctor.proTitle} ${doctor.firstName} ${doctor.lastName}` : '';
  //   }
  //   return returnVal;
  // }

  enterEditMode() {
    this.isEditForm = true;
    this.patientFormGroup.enable();
  }

  exitEditMode() {
    this.isEditForm = false;
    this.showCardSwipe = false;
    this.patientFormGroup.disable();
    this.cancelEditEvent.emit(true);
    this.initForm();
  }

  onChangeCountry(country: CountryData) {
    if (!country) return;
    this.selectedCountryRegions = country.regions;
    this.patientFormGroup.get('mobileNumber').setValidators(phoneNumberValidator(country.slug as CountryCode));
    this.patientFormGroup.get('mobileNumber').updateValueAndValidity();
    this.patientFormGroup.get('mobileNumber').markAsTouched();
    this.phoneNumberFormatter = new AsYouType(country.slug as CountryCode);
    this.patientFormGroup.get('address.postalCode').setValidators(postalCodeValidator(country.slug as CountryCode));
    this.patientFormGroup.get('address.postalCode').updateValueAndValidity();
    this.patientFormGroup.get('address.postalCode').markAsTouched();

    this.patientFormGroup.get('clientId').updateValueAndValidity();
    this.patientFormGroup.get('clientId').markAsTouched();
  }

  async initPatient(patient: Patient) {
    let comp = await this.clinicsService.getClinicById(localStorage.getItem('clinicId')).toPromise();
    patient = {
      patientId: 0,
      clientId: '',
      firstName: '',
      lastName: '',
      nickName: '',
      birthDate: new Date('January 1, 1980'),
      gender: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        country: comp.address.country,
        postalCode: '',
        province: '',
      },
      email: '',
      homeNumber: '',
      mobileNumber: '',
      communicationPreference: '',
      sendAppointmentEmailNotifications: true,
      sendAppointmentSMSNotifications: true,
      sendRetentionEmails: true,
      isPreferred: false,
      socialHistory: [],
      notesAndAlerts: '',
      observations: [],
      serviceAppointments: [],
      signature: '',
      sendReviews: true,
      patientStatus: PatientStatus.Active,
    };
    this.selectedPatient = patient;
  }

  initForm() {
    const doctorId = this.selectedPatient.familyPhysician ? this.selectedPatient.familyPhysician.doctorId : -1;
    const pharmacyId = this.selectedPatient.preferredPharmacy ? this.selectedPatient.preferredPharmacy.pharmacyId : -1;
    const countryName = toTitleCase(this.selectedPatient.address.country ?? '') as CountryName;
    const country = this.getCountryDataByName(countryName);
    const province = this.getRegionDataByName(country, this.selectedPatient.address?.province);

    this.patientFormGroup = this.fb.group({
      mspCardSwipe: [],
      clientId: [this.selectedPatient.clientId, this.validationService.validateMSPNumber],
      firstName: [this.selectedPatient.firstName, [this.validationService.validateName, Validators.required]],
      lastName: [this.selectedPatient.lastName, [this.validationService.validateName, Validators.required]],
      nickName: [this.selectedPatient.nickName, this.validationService.validateName],
      birthDate: [this.selectedPatient.birthDate, this.validationService.validateDate],
      gender: [this.selectedPatient.gender],
      patientStatus: [this.selectedPatient.patientStatus],
      address: this.fb.group({
        address1: [this.selectedPatient.address.address1],
        address2: [this.selectedPatient.address.address2],
        city: [this.selectedPatient.address.city],
        country: [country],
        postalCode: [this.selectedPatient.address.postalCode.toUpperCase(), postalCodeValidator(country.slug)],
        province: [province],
      }),
      mobileNumber: [this.selectedPatient.mobileNumber, phoneNumberValidator(country.slug as CountryCode)],
      email: [
        this.selectedPatient.email,
        Validators.pattern(/^[a-zA-Z0-9]+([.!#$%&'*+\-/=?`}{|]?[\w]+)+@([a-zA-Z0-9]+-?[a-zA-Z0-9]+\.)+[\w-]{2,12}$/),
      ],
      isPreferred: [this.selectedPatient.isPreferred ? true : false],
      familyPhysician: this.fb.group({ doctorId: [doctorId] }),
      preferredPharmacy: this.fb.group({ pharmacyId: [pharmacyId] }),
      sendAppointmentEmailNotifications: [this.selectedPatient.sendAppointmentEmailNotifications ? true : false],
      sendAppointmentSMSNotifications: [this.selectedPatient.sendAppointmentSMSNotifications ? true : false],
      sendReviews: [this.selectedPatient.sendReviews ? true : false],
      sendRetentionEmails: [this.selectedPatient.sendRetentionEmails ? true : false],
      communicationPreference: [this.selectedPatient.communicationPreference],
    });

    this.onChangeCountry(country);

    this.patientFormGroup.valueChanges.subscribe((res) => {
      if (this.errorMessage) {
        this.errorMessage = '';
      }
    });

    this.patientFormGroup
      .get('address.country')
      .valueChanges.subscribe((country: CountryData) => this.onChangeCountry(country));

    this.patientFormGroup.get('mobileNumber').valueChanges.subscribe((mobileInput: string) => {
      const previousInput = this.phoneNumberFormatter.getChars();
      this.phoneNumberFormatter.reset();
      let formatted = this.phoneNumberFormatter.input(mobileInput);
      const newInput = this.phoneNumberFormatter.getChars();
      if (newInput === previousInput && formatted.length - mobileInput.length === 1) {
        formatted = formatted.slice(0, -1);
      }
      this.patientFormGroup.get('mobileNumber').setValue(formatted, { emitEvent: false });
    });

    this.patientFormGroup.get('address.postalCode').valueChanges.subscribe((postalCode: string) => {
      this.patientFormGroup.get('address.postalCode').setValue(postalCode.toUpperCase(), { emitEvent: false });
    });

    // View mode for patient tab
    if (!this.isEditForm) {
      this.patientFormGroup.disable();
    }

    this.loading = false;
  }

  getCountryDataByName(countryName: CountryName): CountryData {
    return this.countries.find((country) => country.name === countryName);
  }

  getRegionDataByName(country: CountryData, regionName: RegionName): RegionData {
    const byFullName = country?.regions.find((region) => region.name === regionName);
    return byFullName ?? country?.regions.find((region) => region.slug === regionName);
  }

  getExampleNumber(): string {
    const exampleNumber = getExampleNumber(this.patientFormGroup.get('address.country').value?.slug, examples);
    return exampleNumber?.formatNational();
  }

  /**
   * Selection change event handler for the family physician drop down.
   * If the form control for family physician has not been set yet, sets it.
   *
   * @param $event The selection event; contains the doctor ID.
   */
  familyPhysicianChanged($event: any): void {
    if ($event.value === -1) {
      this.selectedPatient.doctorId = null;
      return;
    } else if ($event.value === 0) {
      const doctorId = null;
      this.openQuickAddPhysician();
      this.patientFormGroup.controls['familyPhysician'].patchValue(doctorId);
    } else if ($event.value > 0) {
      this.selectedPatient.familyPhysician = {
        doctorId: $event.value, // Only doctorId is needed since this is to be saved as foreign key in DB.
        proTitle: '',
        firstName: '',
        lastName: '',
      };
    }
  }

  /**
   * Selection change event handler for the preferred pharmacy drop down.
   * If the form control for preferred pharmacy has not been set yet, sets it.
   *
   * @param $event The selection event; contains the pharmacy ID.
   */
  preferredPharmacyChanged($event: any): void {
    if ($event.value === -1) {
      this.selectedPatient.preferredPharmacy = null;
      return;
    } else if ($event.value === 0) {
      const pharmacyId = this.selectedPatient.preferredPharmacy
        ? this.selectedPatient.preferredPharmacy.pharmacyId
        : null;
      this.openQuickAddPharmacy();
      this.patientFormGroup.controls['preferredPharmacy'].patchValue(pharmacyId);
    } else if ($event.value > 0) {
      this.selectedPatient.preferredPharmacy = {
        pharmacyId: $event.value, // Only pharmacyId is needed since this is to be saved as foreign key in DB.
        name: '',
      };
    }
  }

  generateValidPHN() {
    let randomMSP = this.mspCardSwipeService.generateValidPHN();
    this.patientFormGroup.controls['clientId'].setValue(randomMSP);
  }

  openQuickAddPhysician() {
    const dialogRef = this.dialog.open(QuickAddPhysicianComponent, {
      panelClass: 'custom-dialog-container',
      data: { doctors: this.theDoctors },
      width: '420px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((physician) => {
        if (physician) {
          this.theDoctors.push(physician);
          this.theDoctors.sort((n1, n2) => {
            let result = 0;
            if (n1.firstName < n2.firstName) {
              result = 1;
            }
            if (n1.firstName > n2.firstName) {
              result = -1;
            }
            return result;
          });
          this.patientFormGroup.controls['familyPhysician'].patchValue({ doctorId: physician.doctorId });
          this.selectedPatient.familyPhysician = {
            doctorId: physician.doctorId, // Only doctorId is needed since this is to be saved as foreign key in DB.
            proTitle: '',
            firstName: '',
            lastName: '',
          };
        } else {
          this.patientFormGroup.controls['familyPhysician'].patchValue({ doctorId: -1 });
          this.selectedPatient.familyPhysician = null;
        }
      });
  }

  openQuickAddPharmacy() {
    const dialogRef = this.dialog.open(QuickAddPharmacyComponent, {
      panelClass: 'custom-dialog-container',
      data: { pharmacies: this.thePharmacies },
      width: '420px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((pharmacy) => {
        if (pharmacy) {
          this.thePharmacies.push(pharmacy);
          this.thePharmacies.sort((n1, n2) => {
            let result = 0;
            if (n1.name < n2.name) {
              result = 1;
            }
            if (n1.name > n2.name) {
              result = -1;
            }
            return result;
          });
          this.patientFormGroup.controls['preferredPharmacy'].patchValue({ pharmacyId: pharmacy.pharmacyId });
          this.selectedPatient.preferredPharmacy = {
            pharmacyId: pharmacy.pharmacyId, // Only pharmacyId is needed since this is to be saved as foreign key in DB.
            name: '',
          };
        } else {
          this.patientFormGroup.controls['preferredPharmacy'].patchValue({ pharmacyId: -1 });
          this.selectedPatient.preferredPharmacy = null;
        }
      });
  }

  clickShowCardSwipe() {
    this.showCardSwipe = true;
  }

  onCardSwipe() {
    const swipe = this.patientFormGroup.get('mspCardSwipe').value;
    if (swipe.length > 0) {
      const patient = this.mspCardSwipeService.processCardSwipe(swipe);
      this.selectedPatient = this.mspCardSwipeService.fillInfoFromSwipe(this.selectedPatient, patient);
      this.initForm();
    }
  }

  sanitizeDoctorPharmacy() {
    if (this.patientService.editedPatient.preferredPharmacy?.pharmacyId === -1) {
      this.patientService.editedPatient.preferredPharmacy.pharmacyId = 0;
    }
    if (this.patientService.editedPatient.familyPhysician?.doctorId === -1) {
      this.patientService.editedPatient.familyPhysician.doctorId = 0;
    }
  }

  trimAllWhiteSpace(object: any) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value == 'string') object[key] = (value as string).trim();
    }
  }

  public savePatientInfo() {
    this.loading = true;
    if (isNullOrUndefined(this.patientFormGroup.value.preferredPharmacy.pharmacyId)) {
      this.patientFormGroup.value.preferredPharmacy = null;
    }
    if (isNullOrUndefined(this.patientFormGroup.value.familyPhysician.doctorId)) {
      this.patientFormGroup.value.familyPhysician = null;
    }
    if (this.isNew) {
      const formGroupPatient = JSON.parse(JSON.stringify(this.patientFormGroup.value));
      this.patientService.editedPatient = Object.assign(this.selectedPatient, formGroupPatient);
      this.selectedPatient.address.country = formGroupPatient.address.country?.name;
      this.selectedPatient.address.province = formGroupPatient.address.province?.name;
      this.sanitizeDoctorPharmacy();
      this.trimAllWhiteSpace(this.patientService.editedPatient);
      if (this.patientService.editedPatient.address) this.trimAllWhiteSpace(this.patientService.editedPatient.address);
      this.patientService.addPatient(this.selectedPatient).subscribe(
        (patient) => {
          this.eventsService.patientListUpdated();
          // TODO: Does catalogue need to be updated here?
          this.catalogueUpdatesService.refreshRequired = true;
          this.catalogueUpdatesService.catalogueUpdateComplete();
          this.patientService.thePatientsSourceHasBeenUpdated();
          this.isEditForm = false;
          this.loading = false;
          this.updatedPatientEvent.emit(patient.patientId);
          this.exitEditMode();
        },
        (error) => {
          if (error.error && error.error.errors) {
            const errors = error.error.errors;
            this.handleServerError(errors);
            this.loading = false;
            this.patientService.editedPatient = null;
          }
        },
        () => {
          this.patientService.editedPatient = null;
        }
      );
    } else {
      try {
        const formGroupPatient = JSON.parse(JSON.stringify(this.patientFormGroup.value));
        this.patientService.editedPatient = Object.assign(this.selectedPatient, formGroupPatient);
        this.selectedPatient.address.country = formGroupPatient.address.country?.name;
        this.selectedPatient.address.province = formGroupPatient.address.province?.name;
        this.sanitizeDoctorPharmacy();
        this.trimAllWhiteSpace(this.patientService.editedPatient);
        if (this.patientService.editedPatient.address)
          this.trimAllWhiteSpace(this.patientService.editedPatient.address);
        this.patientService.updatePatient(this.patientService.editedPatient).subscribe(
          (patient) => {
            this.isEditForm = false;
            this.patientService.thePatientHasBeenUpdatedById(this.patientService.editedPatient.patientId);
            this.currentPatient = this.patientService.editedPatient;
            this.loading = false;
            this.updatedPatientEvent.emit(this.patientService.editedPatient.patientId);
            this.exitEditMode();
          },
          (error) => {
            if (error.error && error.error.errors) {
              console.error('Error editing patient: %o', error);
              const errors = error.error.errors;
              this.handleServerError(errors);
              this.loading = false;
              this.patientService.editedPatient = null;
            }
          },
          () => {
            this.patientService.editedPatient = null;
          }
        );
      } catch (error) {
        console.error('Error editing patient: %o', error);
        this.loading = false;
      }
    }
  }

  handleServerError(errors: any) {
    errors.forEach((err) => {
      const formControl = this.patientFormGroup.get(err.field);
      if (err.field == 'clientId') {
        this.duplicateMspInfo.patientId = err.fieldErrors[0]; // contains patientId of patient with duplicate MSP
        this.duplicateMspInfo.patientName = err.fieldErrors[1]; // contains name of patient with duplicate MSP
        formControl.setErrors({ serverError: true });
      }
      if (err.field == 'email') {
        if (err.fieldErrors[1] != 0) {
          this.duplicateEmailInfo.patientId = err.fieldErrors[0]; // contains patientId of patient with duplicate email
          this.duplicateEmailInfo.name = err.fieldErrors[1]; // contains name of patient with duplicate email
          formControl.setErrors({ serverError: true });
        } else {
          this.duplicateEmailInfo.name = err.fieldErrors[0]; // contains name of patient with duplicate email
          formControl.setErrors({ notUnique: true });
        }
      }

      formControl?.markAsTouched();
      this.errorMessage = err.fieldErrors[0]; // contains generic error when not clientId related.
    });
  }

  navigateToPatient(patientId) {
    this.patientService
      .getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe(async (patient: any) => {
        this.patientService.patientPanelPatient = patient;
        const path = this.router.url.split('(')[0];
        await this.router.navigate([path, { outlets: { 'action-panel': null } }]);
        this.router.navigate([
          path,
          { outlets: { 'action-panel': ['patient', patient.patientId + '_patientprofiletab'] } },
        ]);
      });
  }

  public formatDate(date: any) {
    return moment(date).format('YYYY-MM-DD h:mm A');
  }

  getExistPortalAccount(patientId) {
    this.patientService.existsPatientPortalAccount(patientId).subscribe((result) => {
      if (result) {
        this.patientFormGroup
          .get('email')
          .setValidators([
            Validators.pattern(/^[a-zA-Z0-9]+([.!#$%&'*+\-/=?`}{|]?[\w]+)+@([a-zA-Z0-9]+-?[a-zA-Z0-9]+\.)+[\w-]{2,4}$/),
            Validators.required,
          ]);
        this.patientFormGroup.get('email').updateValueAndValidity();
      }
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
