import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ScriptService } from '@services/script.service';
import { ConvergePaymentsService } from '@services/converge-payments.service';
import { error } from 'console';
import { Patient } from '@models/patient';
import { ConvergeCard } from '@models/payments/converge-card';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-converge-payment',
  templateUrl: './converge-payment.component.html',
  styleUrls: ['./converge-payment.component.less'],
})
export class ConvergePaymentComponent implements OnInit {
  @Input() patient: Patient;
  @Input() cancellationFee: number;
  @Output() cardSelectedEvent: EventEmitter<ConvergeCard> = new EventEmitter<ConvergeCard>();

  private _isDisabled: boolean = false;
  @Input() set isDisabled(val: boolean) {
    this._isDisabled = val;
  }
  get isDisabled() {
    return this._isDisabled;
  }

  @Input() minimumExpiryDate: Date = new Date(Date.now());

  token: string = '';
  txnStatus: string = '';
  txnResponse: string = '';

  PayWithConverge: any;

  convergeCards: ConvergeCard[];
  selectedConvergeCard: ConvergeCard;

  unsub: Subject<void> = new Subject<void>();
  loading = false;

  constructor(
    private scriptService: ScriptService,
    private convergePaymentsService: ConvergePaymentsService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    try {
      await this.scriptService.loadScript('jQuery');
      await this.scriptService.loadScript('PayWithConverge');
    } catch (error) {
      console.error(error);
    }

    this.PayWithConverge = (window as any).PayWithConverge;
    if (!this.PayWithConverge) throw new Error('PayWithConverge.js failed to load properly');

    this.loading = true;
    this.convergePaymentsService
      .getPatientCards(this.patient.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((cards) => {
        this.convergeCards = cards;
        this.loading = false;
      });
  }

  initiateLightbox(): void {
    this.loading = true;
    const tokenRequest = {
      patientId: this.patient.patientId,
      ssl_first_name: this.patient.firstName,
      ssl_last_name: this.patient.lastName,
      ssl_email: this.patient.email,
      ssl_phone: this.patient.mobileNumber,
      ssl_address: this.patient.address?.address1,
      ssl_zipcode: this.patient.address?.postalCode,
      ssl_amount: this.cancellationFee.toString(),
    };
    this.convergePaymentsService.getSessionToken(tokenRequest).subscribe({
      next: (data) => {
        this.token = data.token;
        this.openLightbox();
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
        this.dialog.open(GenericDialogComponent, {
          width: '300px',
          data: {
            showCancel: false,
            title: 'Card Error',
            content: error,
            confirmButtonText: 'Ok',
          },
        });
      },
    });
  }

  openLightbox(): void {
    const paymentFields = {
      ssl_txn_auth_token: this.token,
    };
    const callback = {
      onError: (error: string) => {
        this.loading = false;
        this.dialog.open(GenericDialogComponent, {
          width: '300px',
          data: {
            showCancel: false,
            title: 'Card Error',
            content: error,
            confirmButtonText: 'Ok',
          },
        });
      },
      onCancelled: () => {
        this.loading = false;
      },
      onDeclined: (response: any) => {
        this.loading = false;
        let errorMessage = `${response.errorCode} - ${response.errorName} - ${response.errorMessage}`;
        this.dialog.open(GenericDialogComponent, {
          width: '300px',
          data: {
            showCancel: false,
            title: 'Card Declined',
            content: errorMessage,
            confirmButtonText: 'Ok',
          },
        });
      },
      onApproval: (response: any) => {
        var responseJson = JSON.stringify(response, null, '\t');
        this.convergePaymentsService.savePaymentInfo(this.patient.patientId, response).subscribe({
          next: (card) => {
            this.loading = false;
            // Add the card to the cards collection and select it
            var index = this.convergeCards.findIndex((c) => c.patientTokenId == card.patientTokenId);
            if (index < 0) {
              // Add and select new card
              this.convergeCards.push(card);
              this.selectedConvergeCard = card;
              this.cardSelectedEvent.emit(card);
            } else {
              // Select the card in the list already
              card = this.convergeCards[index];
              this.selectedConvergeCard = card;
              this.cardSelectedEvent.emit(card);
            }
          },
          error: (error) => {
            this.loading = false;
            console.error(error);
            this.dialog.open(GenericDialogComponent, {
              width: '300px',
              data: {
                showCancel: false,
                title: 'Card Add Error',
                content: error,
                confirmButtonText: 'Ok',
              },
            });
          },
        });
      },
    };

    this.PayWithConverge.open(paymentFields, callback);
  }

  onConvergeCardSelected(event: MatRadioChange) {
    this.cardSelectedEvent.emit(event.value);
  }

  compareExpiryToMinimum(card: ConvergeCard) {
    return false;
    //card.exp_year < this.minimumExpiryDate.getUTCFullYear() ||
    //(card.exp_year == this.minimumExpiryDate.getUTCFullYear() &&
    //        card.exp_month < this.minimumExpiryDate.getUTCMonth() + 1)
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
