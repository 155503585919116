<div
  class="input-group"
  [ngClass]="{
    inactive: !sideNavExpanded,
    alternative: alternativeLayout
  }"
>
  <input
    autocomplete="off"
    #typeaheadDirective="bs-typeahead"
    #inputFocus
    [(ngModel)]="asyncSelected"
    [typeaheadAsync]="true"
    [typeahead]="dataSource"
    (typeaheadLoading)="changeTypeaheadLoading($event)"
    (typeaheadOnSelect)="typeaheadOnSelect($event)"
    (typeaheadNoResults)="typeaheadNoResults($event)"
    [typeaheadItemTemplate]="customItemTemplate"
    [optionsListTemplate]="customListTemplate"
    [typeaheadOptionsLimit]="50"
    container="body"
    typeaheadOptionField="formatOption()"
    [typeaheadWaitMs]="400"
    type="text"
    name="patientSearch"
    class="form-control border-0 grey-three-background"
    placeholder="{{ selectedFilter | titlecase }} Search"
    [ngClass]="{ 'patient-search pr-4': !alternativeLayout }"
  />
  <button (click)="openPatientFilterPanel($event)" class="btn em-btn-green ml-1 px-1 white-font">
    <i class="fas fa-filter fa-sm"></i>
  </button>

  <ng-template #customListTemplate let-matches="matches" let-itemTemplate="itemTemplate" let-query="query">
    <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="false" style="max-height: 80vh">
      <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
        <h6 *ngIf="match.isHeader()" class="dropdown-header">{{ match }}</h6>
        <ng-template [ngIf]="!match.isHeader()">
          <button
            #liElements
            class="dropdown-item px-2"
            (mouseenter)="container.selectActive(match)"
            [class.active]="container.isActive(match)"
          >
            <ng-template
              [ngTemplateOutlet]="itemTemplate"
              [ngTemplateOutletContext]="{ item: match.item, index: i, match: match, query: query }"
            >
            </ng-template>
          </button>
        </ng-template>
      </ng-template>
    </ng-scrollbar>

    <!--
          Date: 11-01-2021
          Description: merge patient button
        -->
    <div *ngIf="selection" class="text-center footer">
      <div class="mt-2 search-box border border-radius"></div>
      <button class="btn em-btn em-btn-green mt-2 clear-sec-btn" [disabled]="!mergePatient" (click)="mergePatients()">
        <i class="fal fa-user-friends menu-icon-small mr-1" title="Merge Patients"></i>
        <span>Merge Patients</span>
      </button>
      <button class="btn em-btn em-btn-green mt-2 ml-2 clear-sec-btn" (click)="clearSelection()">
        <!-- <i class="fal fa-user-friends menu-icon-small mr-1" title="Merge Patients"></i> -->
        <i class="fal fa-ban menu-icon-small mr-1" title="Clear Selections"></i>
        <span>Clear Selections</span>
      </button>
    </div>
  </ng-template>

  <ng-template #customItemTemplate let-item="item" let-match="match" let-query="query">
    <div class="col d-flex">
      <!--
            Date: 11-01-2021
            Description: checkbox to selct patients to be merged
          -->
      <input
        type="checkbox"
        class="mr-3 align-self-center"
        (change)="selectPatient(item.patientId, $event)"
        [checked]="isPatientSelected(item.patientId)"
      />
      <div class="d-flex" (click)="patientSelected(item.patientId, match, $event)">
        <div class="d-inline pr-2">
          <div [innerHTML]="formatResultString(item)"></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="showFilter" #patientFilterPanel id="patientFilterPanel">
  <div class="box">
    <div class="top-bar d-flex justify-content-end py-1">
      <button
        class="d-flex justify-content-center align-items-center px-0 py-0 btn pointer"
        (click)="closePatientFilterPanel()"
      >
        <i class="fal fa-times"></i>
      </button>
    </div>
    <div class="container">
      <span class="flex-shrink-0">Filter By:</span>
      <mat-radio-group
        class="mr-2 d-flex flex-column"
        name="selectedSearchFilter"
        [(ngModel)]="selectedFilter"
        (change)="changeFilter()"
      >
        <mat-radio-button class="my-1 pr-2" value="name">Name</mat-radio-button>
        <mat-radio-button class="my-1 pr-2" value="email">Email</mat-radio-button>
        <mat-radio-button class="my-1 pr-2" value="phone">Phone</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
