<!-- [ngClass]="{ shadowed: actionPanelOpened, 'cal-loading': loading }" -->
<!-- (touchstart)="onTouchStart($event)" -->

<full-calendar
  class="h-100"
  [hidden]="!clinic"
  [options]="calendarOptions"
  [events]="events"
  [resources]="resources"
  (contextmenu)="onRightClick($event)"
>
  <ng-template #eventContent let-arg>
    <app-appointment
      *ngIf="
        arg.event.extendedProps.appointment &&
          (scheduleView === ScheduleView.StaffSchedules ||
            arg.event.extendedProps.appointment.appointmentType !== AppointmentType.Staff);
        else staffTile
      "
      [arg]="arg"
      (mouseenter)="onAppointmentHover($event, arg)"
      (click)="onAppointmentClick($event, arg)"
    ></app-appointment>
    <ng-template #staffTile><div class="staff-tile" id="{{ arg.event.id }}"></div></ng-template>
  </ng-template>
</full-calendar>
