import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { ConfirmCancelWithReasonDialogComponent } from '@app/management/dialogs/confirm-cancel-with-reason/confirm-cancel-with-reason.component';
import { CreateNudgesComponent } from '@app/patients/patient-tabs/patient-nudges-tab/create-nudges/create-nudges.component';
import { DateSelectArg, EventClickArg, EventDropArg, EventHoveringArg } from '@fullcalendar/core';
import { EventDragStartArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import { Appointment, AppointmentType } from '@models/appointments/appointment';
import { PaymentStatus } from '@models/appointments/payment-status';
import { Clinic } from '@models/clinic';
import { NudgeReferenceType } from '@models/nudges/reference-type';
import { CancellationType } from '@models/payments/cancellation-type.enum';
import { ResourceType } from '@models/resource-type';
import { ServiceProvider } from '@models/service-provider';
import { Visit } from '@models/visit';
import { ContextMenuComponent, ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { AppointmentSignalrService } from '@services/appointment-signalr.service';
import { AppointmentService } from '@services/appointments.service';
import { ClinicsService } from '@services/clinics.service';
import { EventsService, ScheduleMode, ScheduleView } from '@services/events.service';
import { FinanceService } from '@services/finance.service';
import { PatientFormService } from '@services/patient-form.service';
import { PatientService } from '@services/patient.service';
import { ResourcesService } from '@services/resources.service';
import { ServiceProviderService } from '@services/service-provider.service';
import { SquareService } from '@services/square.service';
import { UsersService } from '@services/users.service';
import { VisitService } from '@services/visit.service';
import * as moment from 'moment';
import { EMPTY, MonoTypeOperatorFunction, Observable, Subject, defer, forkJoin, from } from 'rxjs';
import { filter, finalize, first, mergeMap, takeUntil } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '../../management/dialogs/confirm-delete/confirm-delete.component';
import { GenericDialogComponent } from '../../management/dialogs/generic-confirm/generic-confirm.component';
import { MoveAppointmentDialogComponent } from '../../management/dialogs/move-appointment/move-appointment.component';
import { FcScheduleWrapperComponent } from './fc-schedule-wrapper/fc-schedule-wrapper.component';
import { HoverPanelComponent } from './hover-panel/hover-panel.component';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.less'],
})
export class AppointmentsComponent implements OnInit, OnDestroy {
  @ViewChild('scheduleWrapper') scheduleWrapperRef: ElementRef<FcScheduleWrapperComponent>;
  @ViewChild(HoverPanelComponent) hoverPanel: HoverPanelComponent;
  @ViewChild(TooltipDirective) tooltipDir: TooltipDirective;
  @ViewChild('contextMenu') contextMenu: ContextMenuComponent<AppointmentsComponent>;
  @ViewChild('providersFilterPanel') providersFilterPanel: ElementRef;

  @Input() mobileView = false;
  @Output() cancellationMessage = new EventEmitter();

  private patientId: number;
  private unsub = new Subject<any>();
  private selectedTimeSlotStartTime: any;
  private selectedTimeSlotEndTime: any;
  private selectedTimeSlotResourceId: any;
  private disableHover = false;
  private allowEditMode = false;
  private hoverHideTimer: NodeJS.Timeout;

  providersToDisplay: ServiceProvider[] = [];
  selectedProviderId: string;
  rightClickAppointment: Appointment;
  hoveredAppointment: Appointment;
  shiftPanelOpen = false;
  datePickerVisible = true;
  actionPanelOpened = false;
  providerListLoading: boolean = false;
  configLoaded = false;
  singleTileWidth: number;
  isScheduleEditable = true;
  loadingMessages: string[] = [];
  clinic: Clinic;
  currentDate: Date = new Date();

  ScheduleMode = ScheduleMode;
  ResourceType = ResourceType;
  ScheduleView = ScheduleView;

  appointmentsPolicy = Policies.appointments;
  patientPanelPolicy = Policies.patientPanel;
  patientPanelPolicySatisfied = false;
  appointmentsPolicySatisfied = false;

  get scheduleView(): ScheduleView {
    return this.eventsService.scheduleView;
  }

  get scheduleMode(): ScheduleMode {
    return this.eventsService.scheduleMode;
  }

  get showOnlyWorkingProviders(): boolean {
    return this.userService.loggedInUser?.showOnlyWorkingProvidersOnSchedule;
  }
  set showOnlyWorkingProviders(value: boolean) {
    if (this.userService.loggedInUser) {
      this.userService.loggedInUser.showOnlyWorkingProvidersOnSchedule = value;
      this.userService.toggleUserProviderSetting().toPromise();
    }
    this.setProvidersToDisplay();
  }

  private _allProviders: ServiceProvider[] = [];
  set allProviders(providers: ServiceProvider[]) {
    this._allProviders = providers;
    this.setProvidersToDisplay();
  }
  get allProviders(): ServiceProvider[] {
    return this._allProviders;
  }

  private _workingProviders: ServiceProvider[] = [];
  set workingProviders(providers: ServiceProvider[]) {
    this._workingProviders = providers;
    this.setProvidersToDisplay();
  }
  get workingProviders(): ServiceProvider[] {
    return this._workingProviders;
  }

  @HostListener('click', ['$event'])
  onClick(event: PointerEvent) {
    const filterPanel = this.providersFilterPanel.nativeElement as HTMLElement;
    const target = event.target as HTMLElement;
    if (!filterPanel.contains(target)) {
      this.closeProvidersPanel();
    }
  }

  constructor(
    private financeService: FinanceService,
    private router: Router,
    private visitService: VisitService,
    private patientService: PatientService,
    private deleteDialog: MatDialog,
    private providerService: ServiceProviderService,
    private dialog: MatDialog,
    private appointmentSignalrService: AppointmentSignalrService,
    private authService: AuthService,
    private clinicsService: ClinicsService,
    private userService: UsersService,
    private resourcesService: ResourcesService,
    private patientFormService: PatientFormService,
    private contextMenuService: ContextMenuService<AppointmentsComponent>,
    private squareService: SquareService,
    public appointmentService: AppointmentService,
    public eventsService: EventsService
  ) {}

  // #region Init

  ngOnInit() {
    this.patientPanelPolicySatisfied = this.authService.userSatisfiesPolicy(this.patientPanelPolicy);
    this.appointmentsPolicySatisfied = this.authService.userSatisfiesPolicy(this.appointmentsPolicy);
    this.isScheduleEditable = this.appointmentsPolicySatisfied && !this.mobileView;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const { url } = val;
        if (url.includes('action-panel')) {
          if (!url.includes('edit-patient') && !url.includes('patient')) {
            this.actionPanelOpened = true;
          }
        }
        if (url.includes('action-panel:edit-patient')) {
          this.actionPanelOpened = false;
        }
      }
    });

    this.userService.loggedInUserUpdated$.pipe(takeUntil(this.unsub)).subscribe((user) => {
      if (this.mobileView && user.serviceProvider) {
        this.selectedProviderId = user.id;
        this.selectedProviderChanged();
      }
    });

    this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((date) => {
      this.setCurrentDate(date);
    });

    this.eventsService.closeSidePanel$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.actionPanelOpened = false;
      this.shiftPanelOpen = false;
      this.patientId = null;
    });

    this.eventsService.scheduleViewChanged$.pipe(takeUntil(this.unsub)).subscribe(async (type) => {
      if (this.eventsService.blockedScheduleMode || type !== ScheduleView.Appointments) {
        this.appointmentService.apptsSelected.clear();
        this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
        this.eventsService.closePanel();
      }
      if (type === ScheduleView.NoShowAppointments) {
        this.isScheduleEditable = false;
        this.eventsService.setScheduleMode(ScheduleMode.DayView);
        this.loadCancelationAppointments();
      } else {
        this.isScheduleEditable = this.appointmentsPolicySatisfied && !this.mobileView;
        this.loadAppointments();
      }
    });

    this.eventsService.scheduleModeChangedListener.pipe(takeUntil(this.unsub)).subscribe((mode) => {
      if (mode === ScheduleMode.DayView) {
        this.selectedProviderId = null;
        this.setProvidersToDisplay();
      }
    });

    this.eventsService.appointmentAddedListener.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loadAppointments();
    });

    this.eventsService.appointmentRemovedListener.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loadAppointments();
    });

    this.appointmentService.allApptsUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loadAppointments();
    });

    this.appointmentSignalrService.apptAdded$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      this.onAppointmentUpdate(appointment);
    });

    this.appointmentSignalrService.apptDeleted$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      this.onAppointmentUpdate(appointment);
    });

    this.appointmentSignalrService.apptUpdated$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      this.onAppointmentUpdate(appointment);
    });

    this.clinicsService.clinicIdSelected$.pipe(takeUntil(this.unsub)).subscribe((clinicId) => {
      // currentDate will load the initial appointments, this will re-load on clinic change
      if (this.clinic && this.clinic.clinicId !== clinicId) {
        this.loadAppointments();
        this.loadAllProviders();
        this.loadWorkingProviders();
      }
      this.clinic = this.clinicsService.clinic;
    });

    this.patientFormService.patientFormSubmitted$
      .pipe(
        takeUntil(this.unsub),
        filter((form) => form.appointmentId != null),
        mergeMap((form) => this.appointmentService.getAppointmentById(form.appointmentId))
      )
      .subscribe((appointment) => {
        this.onAppointmentUpdate(appointment);
      });

    this.financeService.invoicePaid$
      .pipe(
        takeUntil(this.unsub),
        mergeMap(() => this.appointmentService.updateActiveAppointments(null, this.currentDate, this.currentDate))
      )
      .subscribe();

    this.loadAllProviders();
  }

  ngAfterViewInit() {
    this.addProvidersFilterToggleButton();
  }

  // #endregion

  // #region Data Loading

  private setCurrentDate(newDate: Date) {
    this.allowEditMode = false;
    if (this.currentDate !== newDate) {
      this.currentDate = new Date(newDate);
      this.loadAppointments();
      this.loadWorkingProviders();
    }
  }

  private async loadAppointments() {
    if (this.eventsService.scheduleMode === ScheduleMode.DayView) {
      await this.appointmentService
        .updateActiveAppointments(null, this.currentDate, this.currentDate)
        .pipe(this.loadingOperator('Loading Appointments'))
        .toPromise();
    } else {
      await this.appointmentService
        .updateActiveAppointments(
          moment(this.currentDate).startOf('week').toDate(),
          null,
          null,
          this.selectedProviderId
        )
        .pipe(this.loadingOperator('Loading Appointments'))
        .toPromise();
    }
  }

  private async loadCancelationAppointments() {
    await this.appointmentService
      .updateActiveNoShowAppointments()
      .pipe(this.loadingOperator('Loading Appointments'))
      .toPromise();
  }

  private async loadAllProviders() {
    this.allProviders = await this.providerService
      .getServiceProviders()
      .pipe(this.loadingOperator('Loading Providers'), first())
      .toPromise();
  }

  private async loadWorkingProviders() {
    this.workingProviders = await this.providerService
      .getServiceProviderByDate(this.currentDate)
      .pipe(this.loadingOperator('Loading Providers'))
      .toPromise();
  }

  private setProvidersToDisplay() {
    if (this.selectedProviderId || this.mobileView) {
      const selectedProviderActive =
        this.selectedProviderId && this.allProviders.some((p) => p.id === this.selectedProviderId);
      if (!selectedProviderActive && this.mobileView && this.allProviders.length > 0) {
        this.selectedProviderId = this.allProviders[0].id;
      }
      this.providersToDisplay = this.allProviders.filter((p) => p.id === this.selectedProviderId);
    } else if (this.showOnlyWorkingProviders) {
      this.providersToDisplay = this.allProviders.filter(
        (p) => p.visible && this.workingProviders?.some((w) => w.id === p.id)
      );
      if (this.providersToDisplay.length === 0) {
        this.providersToDisplay.push(<ServiceProvider>{
          id: 'none',
          title: 'No working staff selected, use button on the left to choose visible staff',
        });
      }
    } else {
      this.providersToDisplay = this.allProviders.filter((p) => p.visible);
      if (this.providersToDisplay.length === 0) {
        this.providersToDisplay.push(<ServiceProvider>{
          id: 'none',
          title: 'No staff selected, use button on the left to choose visible staff',
        });
      }
    }
  }

  private onAppointmentUpdate(appointment: Appointment) {
    if (this.checkIfUpdatesAffectCurrentScheduleView(appointment)) {
      let isDailyView = this.eventsService.scheduleMode == ScheduleMode.DayView;
      this.appointmentService.onAllApptsUpdated(
        isDailyView ? undefined : moment(this.currentDate).startOf('week').toDate(),
        isDailyView ? undefined : appointment.staffId
      );
    }
  }

  private checkIfUpdatesAffectCurrentScheduleView(appointment: Appointment) {
    if (this.providersToDisplay.some((provider) => provider.id == appointment.staffId)) {
      //if provider isnt showing then dont update calendar
      if (this.eventsService.scheduleMode == ScheduleMode.DayView) {
        //One day being shown on calendar
        if (appointment && moment(appointment.date).isSame(moment(this.currentDate), 'day')) {
          return true;
        }
      } else {
        //showing weekly mode starting from currentDate + 5 days and matches the provider
        if (
          appointment &&
          appointment.staffId == this.selectedProviderId &&
          moment(appointment.date).isBetween(moment(this.currentDate), moment(this.currentDate).add(5, 'days'))
        ) {
          return true;
        }
      }
    }
    return false;
  }

  // #endregion

  // #region Event Handlers

  onTimeSlotSelected(selection: DateSelectArg) {
    if (this.mobileView) {
      return;
    }
    const start = selection.start;
    const end = selection.end;
    const resourceId = selection.resource.id;
    const provider = this.allProviders.find((p) => p.id === resourceId);
    if (this.scheduleView !== ScheduleView.StaffSchedules && !this.appointmentsPolicySatisfied) {
      return;
    }

    this.visitService.clickedVisitAppt = null;
    if (this.eventsService.movingAppointment) {
      this.verifyMovedAppointment(start, provider);
    } else {
      const clickEvent = { isSelection: true, start, end, provider: provider };
      this.eventsService.setTempEvent(clickEvent);
      if (!this.actionPanelOpened) {
        this.toggleCreateVisitPanel(start, end, provider.id);
        this.toggleVisitPanel('_');
      }
    }

    const isSameDate = moment(this.currentDate).isSame(start, 'day');
    if (this.visitService.reservation && isSameDate) {
      return;
    }
    if (
      !this.visitService.reservation &&
      this.visitService.lastVisit &&
      this.patientService.reservationPatient //this is switched to editPatient from patientPanelPatien
    ) {
      return;
    }
  }

  onResourceSelected(resourceId: string) {
    if (!this.mobileView) {
      this.selectedProviderId = resourceId;
      this.selectedProviderChanged();
    }
  }

  selectedProviderChanged() {
    this.setProvidersToDisplay();
    if (!this.mobileView) {
      this.eventsService.setScheduleMode(ScheduleMode.WeekView);
    }
    this.loadAppointments();
  }

  async onEventDropped(eventChange: EventDropArg) {
    this.hideHoverPanel();
    const appointment = eventChange.event.extendedProps.appointment as Appointment;
    const appointmentStartTime = eventChange.oldEvent.start;
    const isLocked = await this.checkAppointmentIsLocked(appointment, appointmentStartTime);
    if (isLocked) {
      eventChange.revert();
      return;
    }
    if (this.eventsService.scheduleMode === ScheduleMode.DayView) {
      this.moveAppointmentWithinDay(appointment, eventChange);
    } else if (this.eventsService.scheduleMode === ScheduleMode.WeekView) {
      this.clearAllApptMarksForMove();
      this.appointmentService.apptsMarkedForMove.add(appointment.appointmentId);
      await this.moveAppointmentsBetweenDays(
        appointment.start,
        eventChange.newResource.id,
        appointment.visitId,
        eventChange.revert
      );
    }
    this.disableHover = false;
    this.appointmentService.appointmentUpdated.next(appointment);
  }

  onEventDragStarted(eventChange: EventDragStartArg) {
    this.disableHover = true;
    this.hideHoverPanel();
  }

  async onEventResized(eventChange: EventResizeDoneArg) {
    this.hideHoverPanel();
    const appointment = eventChange.event.extendedProps.appointment as Appointment;
    const appointmentStartTime = eventChange.oldEvent.start;
    const isLocked = await this.checkAppointmentIsLocked(appointment, appointmentStartTime);
    if (isLocked) {
      eventChange.revert();
      return;
    }

    this.appointmentService.setAppointmentTileLoading(appointment.appointmentId, true);

    const newStart = eventChange.event.start;
    const newEnd = eventChange.event.end;
    const newStartTime = moment.duration(moment(newStart).format('HH:mm'));
    const newEndTime = moment.duration(moment(newEnd).format('HH:mm'));

    appointment.start = newStart;
    appointment.end = newEnd;
    appointment.startTime = newStartTime;
    appointment.endTime = newEndTime;

    if (appointment.appointmentType == AppointmentType.Regular) {
      const updateConfirmationStatus = eventChange.oldEvent.start != newStart;
      await this.validateAndUpdateAppointment(
        appointment,
        appointment.resourceId,
        updateConfirmationStatus,
        eventChange.revert
      );
    } else {
      await this.appointmentService.updateAppointment(appointment).toPromise();
    }
    this.appointmentService.appointmentUpdated.next(appointment);
  }

  onEventHovered(eventHover: EventHoveringArg) {
    if (this.mobileView) {
      return;
    }
    const event = eventHover.jsEvent;
    const appointment = eventHover.event.extendedProps.appointment as Appointment;
    const appointmentType = this.eventsService.blockedScheduleMode ? AppointmentType.Regular : AppointmentType.Blocked;
    if (
      (appointment.appointmentType === appointmentType && this.scheduleView === 'Appointments') ||
      appointment.isPlaceholder
    ) {
      event.stopPropagation();
      return;
    }
    event.target.addEventListener('mouseleave', (event: MouseEvent) => {
      this.hideHoverPanel();
    });
    this.displayHoverPanel(appointment, event);
  }

  onEventClicked(eventClick: EventClickArg) {
    const jsEvent = eventClick.jsEvent;
    const appointment = eventClick.event.extendedProps.appointment as Appointment;
    const appointmentType = this.eventsService.blockedScheduleMode ? AppointmentType.Regular : AppointmentType.Blocked;
    if (
      !appointment ||
      (appointment.appointmentType === appointmentType && this.scheduleView === ScheduleView.Appointments) ||
      this.scheduleView === ScheduleView.NoShowAppointments ||
      appointment.isPlaceholder
    ) {
      jsEvent.stopPropagation();
      return;
    }
    this.hideHoverPanel();
    if (appointment && this.appointmentsPolicySatisfied) {
      if (this.mobileView) {
        this.openPatientPanel(appointment);
      } else {
        this.appointmentSelected(appointment);
        this.openVisitPanel(appointment);
      }
    }
  }

  onRightClicked(event: MouseEvent) {
    if (this.mobileView) {
      return;
    }
    this.disableHover = true;
    if (this.hoveredAppointment && this.appointmentsPolicySatisfied) {
      this.rightClickAppointment = this.hoveredAppointment;
      this.contextMenuService.show(this.contextMenu, { x: event.x, y: event.y });
      this.contextMenu.close.subscribe(() => {
        this.disableHover = false;
      });
    }
  }

  // #endregion

  // #region Appointment Selected

  private appointmentSelected(appointment: Appointment) {
    this.eventsService.movingAppointment = false;
    const siblingAppointments: Appointment[] = [];
    this.appointmentService.getRegularScheduleAppointmentsByDate(this.currentDate, null).subscribe((todaysAppts) => {
      const todaysApptsForThisVisit: Appointment[] = todaysAppts.filter((a) => a.visitId === appointment.visitId);
      if (todaysApptsForThisVisit.length > 0) {
        if (!this.appointmentService.apptsSelected.has(todaysApptsForThisVisit[0].appointmentId)) {
          this.appointmentService.apptsSelected.clear();
        }
      }
      todaysApptsForThisVisit.forEach((a) => {
        siblingAppointments.push(a);
        const targetAppt = this.appointmentService.activeAppointments.find(
          (appt) => appt.appointmentId === a.appointmentId
        );
        if (!this.appointmentService.apptsSelected.has(a.appointmentId)) {
          this.appointmentService.apptsSelected.set(targetAppt.appointmentId, targetAppt.appointmentId);
        }
        this.clearAllApptMarksForMove();
      });
      this.visitService.clickedVisitAppt = { id: appointment.appointmentId, visitId: appointment.visitId };
      if (this.allowEditMode) {
        this.visitService.activeVisitApptsUpdated.next(appointment);
      }
      this.allowEditMode = true;
    });
  }

  // #endregion

  // #region Visit Panel

  private openVisitPanel(appointment: Appointment) {
    const provider = this.allProviders.find((p) => p.id === appointment.resourceId);

    const clickEvent = {
      isSelection: false,
      start: appointment.start,
      end: appointment.end,
      provider: provider,
    };
    this.eventsService.setTempEvent(clickEvent);
    this.patientId = appointment.patientId;
    this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);

    if (this.scheduleView !== ScheduleView.StaffSchedules) {
      if (!this.eventsService.blockedScheduleMode) {
        this.toggleVisitPanel(appointment.visitId.toString());
      } else {
        this.toggleVisitPanel(appointment.appointmentId + '_B');
      }
    } else {
      if (appointment.appointmentType === AppointmentType.Blocked) {
        this.toggleVisitPanel(appointment.appointmentId + '_B');
      } else {
        this.toggleVisitPanel(appointment.appointmentId + '_S');
      }
    }
  }

  private toggleVisitPanel(id: string) {
    if (this.scheduleView !== ScheduleView.StaffSchedules && !this.eventsService.blockedScheduleMode) {
      this.actionPanelOpened = true;
      this.router.navigate([
        '/schedule',
        { outlets: { 'action-panel': ['visit-details', id, this.patientId ? this.patientId : '_'] } },
      ]);
    } else {
      this.actionPanelOpened = true;
      this.shiftPanelOpen = true;
      this.router.navigate(['schedule', { outlets: { 'action-panel': ['create-shift', id] } }]);
    }
  }

  private toggleCreateVisitPanel(start: Date, end: Date, resourceId: string) {
    if (!this.actionPanelOpened) {
      this.actionPanelOpened = true;
      this.selectedTimeSlotStartTime = start;
      this.selectedTimeSlotEndTime = end;
      this.selectedTimeSlotResourceId = resourceId;
    } else {
      if (this.areMomentsBetweenSelectedStartEnd(start, end, resourceId)) {
        this.selectedTimeSlotStartTime = null;
        this.selectedTimeSlotEndTime = null;
        this.selectedTimeSlotResourceId = null;
        this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
        this.eventsService.closeCreateVisitPanel.next();
      } else {
        this.actionPanelOpened = true;
        this.selectedTimeSlotStartTime = start;
        this.selectedTimeSlotEndTime = end;
        this.selectedTimeSlotResourceId = resourceId;
      }
    }
  }

  private areMomentsBetweenSelectedStartEnd(start, end, resourceId) {
    const isStartEqualOrAfter = moment(this.selectedTimeSlotStartTime).isSameOrAfter(start);
    const isEndEqualOrBefore = moment(this.selectedTimeSlotEndTime).isSameOrBefore(end);
    const isResourceEqual = this.selectedTimeSlotResourceId === resourceId ? true : false;

    if (isStartEqualOrAfter && isEndEqualOrBefore && isResourceEqual) {
      return true;
    } else {
      return false;
    }
  }

  // #endregion

  // #region Hover Panel

  displayHoverPanel(appointment: Appointment, event: MouseEvent) {
    this.hoveredAppointment = appointment;
    if (!this.disableHover && this.hoverPanel && this.hoveredAppointment.patient) {
      this.stopHoverHide();
      this.hoverPanel.display = true;
      setTimeout(() => this.setHoverPanelPosition(event));
    }
  }

  private setHoverPanelPosition(event: MouseEvent) {
    const hoveredElement = event.target as HTMLElement;
    const eventBounding = hoveredElement.getBoundingClientRect();
    if (eventBounding.top !== 0 && eventBounding.left !== 0) {
      const scrollLeft = window.scrollX;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const panelBounding = this.hoverPanel.getBoundingClientRect();
      const setTop = eventBounding.top + scrollTop;
      const setLeft = eventBounding.left + scrollLeft + eventBounding.width;

      // put the hover panel in the default position
      this.hoverPanel.positionTop = setTop + 'px';
      this.hoverPanel.positionLeft = setLeft + 'px';
      const windowWidth = this.actionPanelOpened
        ? (window.innerWidth || document.documentElement.clientWidth) - 420
        : window.innerWidth || document.documentElement.clientWidth;

      // the right side of the hover panel is beyond the viewport, so shift it to the secondary position
      if (setLeft + panelBounding.width > windowWidth) {
        this.hoverPanel.positionLeft = setLeft - panelBounding.width - eventBounding.width + 'px';
      }

      // now if anything is out of bounds it will be the bottom, so pull it up til it is all visible
      if (setTop + panelBounding.height > (window.innerHeight || document.documentElement.clientHeight)) {
        this.hoverPanel.positionTop =
          (window.innerHeight || document.documentElement.clientHeight) - panelBounding.height + 'px';
      }
    } else {
      this.hoverPanel.positionTop = 'auto';
      this.hoverPanel.positionLeft = 'auto';
    }
  }

  hideHoverPanel() {
    if (!this.hoverHideTimer && this.hoverPanel) {
      this.hoverHideTimer = setTimeout(() => {
        this.hoverPanel.display = false;
        this.hoveredAppointment = null;
        this.hoverHideTimer = null;
      }, 400);
    }
  }

  stopHoverHide() {
    clearTimeout(this.hoverHideTimer);
    this.hoverHideTimer = null;
  }

  // #endregion

  // #region Providers Filter

  private addProvidersFilterToggleButton() {
    const axisHeader = document.getElementsByClassName('fc-timegrid-axis-frame');
    if (axisHeader.length) {
      const el = axisHeader[0];
      if (el.childNodes.length === 0) {
        const div = el as HTMLDivElement;
        div.classList.add('users-btn');
        const i = document.createElement('i');
        i.classList.add('fal');
        i.classList.add('fa-users');
        div.appendChild(i);
        div.onclick = (e: PointerEvent) => this.showProvidersFilterPanel(e);
      }
    }
  }

  private showProvidersFilterPanel(event: PointerEvent) {
    event.stopPropagation();
    const panelElement = document.getElementById('providersFilterPanel');
    if (panelElement.className === 'show-panel') {
      this.closeProvidersPanel();
    } else {
      panelElement.className = 'show-panel';
      const parentBounding = (event.target as HTMLElement).parentElement.getBoundingClientRect();
      panelElement.style.top = parentBounding.bottom + 'px';
      panelElement.addEventListener('mouseleave', (e) => {
        this.closeProvidersPanel();
      });
    }
  }

  closeProvidersPanel() {
    const panelElement = document.getElementById('providersFilterPanel');
    panelElement.className = '';
  }

  async providerSelected(provider: ServiceProvider) {
    provider.visible = !provider.visible;
    this.providerListLoading = true;
    if (provider.visible) {
      await this.providerService.addServiceProviderVisibleOnSchedule(provider.id).toPromise();
    } else {
      await this.providerService.removeServiceProviderVisibleOnSchedule(provider.id).toPromise();
    }
    this.providerListLoading = false;
    this.setProvidersToDisplay();
  }

  async onProviderDropped(event: CdkDragDrop<ServiceProvider[]>) {
    this.providerListLoading = true;
    moveItemInArray(this.allProviders, event.previousIndex, event.currentIndex);
    await this.providerService.updateServiceProvidersOrder(this.allProviders.map((_) => _.id)).toPromise();
    this.allProviders.forEach((provider, i) => {
      provider.order = i;
    });
    this.setProvidersToDisplay();
    this.providerListLoading = false;
  }

  public clearAllVisibleProviders() {
    this.providerListLoading = true;
    this.allProviders.forEach((provider) => {
      provider.visible = false;
    });
    this.providerService
      .removeAllServiceProvidersVisibleOnSchedule()
      .pipe(this.loadingOperator('Clearing Providers'))
      .subscribe(() => {});
    this.setProvidersToDisplay();
  }

  public selectAllVisibleProviders() {
    this.providerListLoading = true;
    let requests = [];
    this.allProviders.forEach((provider) => {
      provider.visible = true;
      requests.push(this.providerService.addServiceProviderVisibleOnSchedule(provider.id));
    });
    forkJoin(requests)
      .pipe(this.loadingOperator('Adding Providers'))
      .subscribe(() => {});
    this.setProvidersToDisplay();
  }

  // #endregion

  // #region Update & Validation

  private async validateAndUpdateAppointment(
    apptToMove: Appointment,
    providerId: string,
    updateConfirmationStatus: boolean,
    revertAppointmentMove: () => void
  ) {
    const resources = await this.resourcesService
      .getResourcesAllocated(
        undefined,
        apptToMove.date,
        apptToMove.startTime,
        apptToMove.endTime,
        apptToMove.appointmentId
      )
      .toPromise();

    if (resources.length > 0) {
      let appointmentResourceIds = apptToMove.service?.serviceResources?.map((b) => b.resourceId);
      let matchingResources = resources.filter((r) => appointmentResourceIds.find((ar) => ar == r.resourceId));

      if (matchingResources.length > 0) {
        let resourceMessage = this.appointmentService.getResourceConflictMessage(matchingResources);
        this.appointmentService.validateResourceConflict(
          resourceMessage,
          () => this.validateAppointment(apptToMove, providerId, updateConfirmationStatus, revertAppointmentMove),
          revertAppointmentMove,
          apptToMove.staffId
        );
        return;
      }
    }

    this.validateAppointment(apptToMove, providerId, updateConfirmationStatus, revertAppointmentMove);
  }

  private validateAppointment(
    appointment: Appointment,
    providerId: string,
    updateConfirmationStatus: boolean,
    revertAppointmentMove: () => void
  ) {
    const durationMinutes = appointment.endTime.asMinutes() - appointment.startTime.asMinutes();
    const visit = this.appointmentService.activeAppointmentVisits.get(appointment.visitId);

    this.appointmentService.validateAppointment(
      moment(appointment.start).toDate(),
      durationMinutes,
      providerId,
      () => {
        this.appointmentService.updateAppointment(appointment).subscribe(async (appt) => {
          if (updateConfirmationStatus) await this.visitService.updateAutoConfirmation(appt, visit, true);
        });
      },
      revertAppointmentMove
    );
  }

  private async moveAppointmentWithinDay(appointment: Appointment, eventChange: EventDropArg) {
    if (appointment.appointmentId === 0) {
      return;
    }
    this.appointmentService.setAppointmentTileLoading(appointment.appointmentId, true);
    const newStart = eventChange.event.start;
    const newEnd = eventChange.event.end;
    const newProviderId = eventChange.newResource?.id;
    const newStartTime = moment.duration(moment(newStart).format('HH:mm'));
    const newEndTime = moment.duration(moment(newEnd).format('HH:mm'));

    if (appointment.appointmentType === AppointmentType.Regular && newProviderId) {
      const isProviderAuthorized = this.isProviderAuthorizedForMove(appointment, newProviderId);
      if (!isProviderAuthorized) {
        eventChange.revert();
        this.appointmentService.setAppointmentTileLoading(appointment.appointmentId, false);
        return;
      }
    }

    if (newProviderId) {
      appointment.staffId = newProviderId;
      appointment.resourceId = newProviderId;
    }

    appointment.start = newStart;
    appointment.end = newEnd;
    appointment.startTime = newStartTime;
    appointment.endTime = newEndTime;

    if (appointment.appointmentType === AppointmentType.Staff) {
      await this.appointmentService.updateAppointment(appointment).toPromise();
    } else if (appointment.appointmentType === AppointmentType.Blocked) {
      await this.appointmentService.updateAppointment(appointment).toPromise();
    } else {
      await this.validateAndUpdateAppointment(appointment, appointment.staffId, true, eventChange.revert);
    }
    this.appointmentService.setAppointmentTileLoading(appointment.appointmentId, false);
  }

  private async isProviderAuthorizedForMove(appointment: Appointment, providerId: string) {
    const provider = await this.providerService.getServiceProviderById(providerId).toPromise();
    if (!provider.authorizedServiceIds.some((authServiceId) => authServiceId === appointment.service.templateId)) {
      this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Error',
          content: "This provider can't perform the booked service!",
          confirmButtonText: 'Ok',
          showCancel: false,
        },
      });
      return false;
    }
    return true;
  }

  // #endregion

  // #region Moving

  /**
   * This is me trying to describe simply the sequence that the previous devs implemented for Moving appointments
   * 1. Prompt to confirm move
   * 2. Get visit of selected appointment
   * 3. Clone the visit
   * 4. Remove all appointments from newly cloned visit
   * 5a. If target date of move is the same date as today then:
   *    a) get the visit for the target patient on selected target day, if one exists then set cloned visit id as that, otherwise set as 0 to create a new one
   * 5b. If target date is same date then use clone visit
   * 6. Loop through appointments marked for move and get their details including lock status
   * 7. Check each appointment for lock status and deny moving if any are locked
   * 8. Update all appointments ?
   * 9. Create new appointments based on visit from 5 and appointments returned from update in step 8
   * 10. Remove all appointments from step 7
   * 11. Get original visit from step 2 and if it has no appointments then delete it
   */
  private moveAppointment(targetStart: Date, staffId: string) {
    this.eventsService.movingAppointment = false;

    const dialogRef = this.deleteDialog.open(MoveAppointmentDialogComponent, {
      width: '250px',
      data: {
        targetDate: targetStart,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe(async (dialogResponse) => {
        if (dialogResponse === 'confirm') {
          const fromVisitId = this.rightClickAppointment.visitId;
          await this.moveAppointmentsBetweenDays(targetStart, staffId, fromVisitId);
        } else {
          if (dialogResponse === 'cancel') {
            this.clearAllApptMarksForMove();
          }
        }
      });
  }

  private async moveAppointmentsBetweenDays(
    targetDate: Date,
    staffId: string,
    fromVisitId: number,
    revertFunc?: () => void
  ) {
    const toVisit = await from(this.visitService.getVisitForAppointmentMove(fromVisitId, targetDate))
      .pipe(this.loadingOperator('Moving Appointments'))
      .toPromise();
    const didMove = await from(
      this.appointmentService.moveAppointments(
        toVisit,
        fromVisitId,
        moment(targetDate),
        staffId,
        this.allProviders,
        this.workingProviders
      )
    )
      .pipe(this.loadingOperator('Moving Appointments'))
      .toPromise();
    const isNewVisit = toVisit.visitId != fromVisitId;
    if (!didMove && isNewVisit) {
      this.visitService.removeVisit(toVisit);
    }
    if (!didMove && revertFunc) {
      revertFunc();
    }
  }

  private verifyMovedAppointment(start: Date, provider: ServiceProvider) {
    const getAppointmentCalls = [];
    this.appointmentService.apptsMarkedForMove.forEach((value) => {
      getAppointmentCalls.push(this.appointmentService.getAppointmentById(value));
    });

    forkJoin(getAppointmentCalls).subscribe(async (appointmentsToMove: Appointment[]) => {
      // Sort appointments by start time.
      appointmentsToMove.sort((a, b) => (a.startTime > b.startTime ? 1 : a.startTime < b.startTime ? -1 : 0));
      // Check if the first appointment's provider is being changed.
      // If it is being changed, check if they can do the Service.
      // If is is not being changed, we can check if the other ones are scheduled.
      const firstAppointment = appointmentsToMove[0];
      if (firstAppointment.staffId !== provider.id) {
        const providerAuthorized = this.appointmentService.verifyMovedAppointmentForProvider(
          firstAppointment,
          provider
        );
        if (!providerAuthorized) {
          // Determine which Services the provider can't perform.
          const serviceProvider = this.workingProviders.filter((s) => s.id === provider.id);
          const message = `<strong>${serviceProvider[0].title}</strong> cannot perform the <strong>${firstAppointment.service.serviceName}</strong> service.`;

          this.dialog.open(GenericDialogComponent, {
            width: '300px',
            data: {
              title: 'Error - Not Authorized',
              content: message,
              confirmButtonText: 'Ok',
              showCancel: false,
            },
          });
          return;
        }
      }

      let withinSchedule = true;
      let serviceIsLocked = false;

      const appTimeDiff = start.valueOf() - firstAppointment.start.valueOf();
      for (const appointment of appointmentsToMove) {
        const newStart = new Date(appointment.start.valueOf() + appTimeDiff);
        const newEnd = new Date(appointment.end.valueOf() + appTimeDiff);

        if (!(await this.appointmentService.isWithinStaffSchedule(appointment.staffId, newStart, newEnd))) {
          withinSchedule = false;
          const serviceProvider = this.workingProviders.filter((s) => s.id === appointment.staffId);
          const message = `<strong>${appointment.service.serviceName}</strong> at <strong>${moment(newStart).format(
            'h:mm A'
          )}</strong> will be outside of <strong>${serviceProvider[0].title}'s</strong> schedule.`;
          const dialogRef = this.dialog.open(GenericDialogComponent, {
            width: '300px',
            data: {
              title: 'Error - Outside Schedule',
              content: message,
              confirmButtonText: 'Ok',
              showCancel: false,
            },
          });
          await dialogRef.afterClosed().toPromise();
        }

        if (await this.checkAppointmentIsLocked(appointment, appointment.start)) {
          serviceIsLocked = true;
        }
      }

      if (!withinSchedule || serviceIsLocked) {
        this.eventsService.movingAppointment = false;
        this.clearAllApptMarksForMove();
        return false;
      }

      this.moveAppointment(start, provider.id);
    });
  }

  // #endregion

  // #region Context Menu

  createNudge() {
    const dialogRef = this.dialog.open(CreateNudgesComponent, {
      panelClass: 'custom-dialog-container',
      width: '550px',
      data: {
        patientId: this.rightClickAppointment.patient.patientId,
        referenceId: +this.rightClickAppointment.service.serviceId,
        referenceType: NudgeReferenceType.Service,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  cancelMove() {
    this.clearAllApptMarksForMove();
  }

  async cancelAppointment() {
    const appointment = new Appointment(this.rightClickAppointment);
    const cancelled = await from(this.appointmentService.handleCancellation(appointment))
      .pipe(this.loadingOperator('Cancelling Appointment'))
      .toPromise();
    if (cancelled) {
      this.eventsService.closePanel();
      this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
    }
  }

  cancelVisit() {
    this.visitService.getVisitById(this.rightClickAppointment.visitId).subscribe((visit) => {
      if (this.canVisitBeCanceled(visit)) {
        const dialogRef = this.deleteDialog.open(ConfirmCancelWithReasonDialogComponent, {
          width: '400px',
          data: {
            title: 'Cancel the Entire Visit?',
            result: '',
            selectedCancelReason: '',
            customCancelReason: '',
            visitId: this.rightClickAppointment.visitId,
          },
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsub))
          .subscribe((result) => {
            if (result.event === 'confirm') {
              const dialogConfirmCancelData = dialogRef.componentInstance.data;
              // Check for cancellation charge
              const visitId = this.rightClickAppointment.visitId;
              const appointmentId = this.rightClickAppointment.appointmentId;
              this.appointmentService.handleCancellationCharge(
                dialogConfirmCancelData,
                CancellationType.Visit,
                visitId,
                appointmentId
              );
              // Record the no charge for cancellation reason
              if (dialogConfirmCancelData.isCancelChargeable) {
                if (!dialogConfirmCancelData.chargeCancelAppointment) {
                  visit.reasonCancellationNotCharged = dialogConfirmCancelData.reasonCancellationNotCharged;
                }
              }
              visit.cancelledByUserId = this.userService.loggedInUser.id;
              visit.cancellationDate = new Date();
              visit.cancelled = true;
              visit.cancellationReason = dialogConfirmCancelData.selectedCancelReason;
              visit.cancellationMessage = dialogConfirmCancelData.customCancelReason;
              this.visitService
                .updateVisit(visit)
                .pipe(this.loadingOperator('Cancelling Appointments'))
                .subscribe((visit) => {
                  // Need to update all appointments because they should be removed from the view
                  this.appointmentService.onAllApptsUpdated();
                  this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
                });

              this.eventsService.closePanel();
            }
          });
      } else {
        const dialogRef = this.deleteDialog.open(GenericDialogComponent, {
          width: '330px',
          data: {
            title: 'Cannot Cancel Visit',
            content:
              "One or more of this patient's appointments have already been paid and/or their chart entry is locked. Cancel individually.",
            confirmButtonText: 'OK',
            showCancel: false,
          },
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsub))
          .subscribe((result) => {});
      }
    });
  }

  private canVisitBeCanceled(visit: Visit) {
    let canCancel = true;

    //iterate through each appointment
    visit.appointments.forEach((appointment) => {
      if (
        appointment &&
        appointment.service && //if an appointment is locked or paid then do not allow visit canceelling
        (appointment.service.isLocked ||
          (appointment.paymentStatus == PaymentStatus.Paid && !appointment.service.isPrepaid))
      ) {
        //if an appt is paid
        canCancel = false;
      }
    });

    return canCancel;
  }

  markAppointmentForMove() {
    this.clearAllApptMarksForMove();
    this.clearAllApptSelections();

    // Check if there's more than one appointment to move
    // Get all the appointments for this visit
    this.appointmentService.getAppointmentsByVisitId(this.rightClickAppointment.visitId).subscribe((visitAppts) => {
      if (visitAppts.length > 1) {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          width: '300px',
          data: {
            title: 'Warning: Multiple Appointments for Patient',
            content:
              "There's more than one appointment for this patient. Are you sure you want to move just the single selected appointment?",
            confirmButtonText: 'Confirm',
            showCancel: true,
          },
        });
        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsub))
          .subscribe(async (dialogResponse) => {
            if (dialogResponse === 'confirm') {
              this.appointmentService.apptsMarkedForMove.add(this.rightClickAppointment.appointmentId);
              this.eventsService.movingAppointment = true;
            } else {
              if (dialogResponse === 'cancel') {
                this.clearAllApptMarksForMove();
              }
            }
          });
      } else {
        this.appointmentService.apptsMarkedForMove.add(this.rightClickAppointment.appointmentId);
        this.eventsService.movingAppointment = true;
      }
    });
  }

  markVisitForMove() {
    this.clearAllApptMarksForMove();
    this.clearAllApptSelections();
    // get all the appointments for this visit
    this.appointmentService.getAppointmentsByVisitId(this.rightClickAppointment.visitId).subscribe((visitAppts) => {
      visitAppts.forEach((va) => {
        if (!va.cancelled && va.startTime.asMilliseconds() !== va.endTime.asMilliseconds()) {
          /* Ignore cancelled and chart appointments when marking for move */
          this.appointmentService.apptsMarkedForMove.add(va.appointmentId);
        }
      });
    });
    this.eventsService.movingAppointment = true;
    this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Moving All Appointments',
        content:
          "Click a time slot to move all of today's appointments for this patient at once, relative to the selected appointment.",
        confirmButtonText: 'Ok',
        showCancel: false,
      },
    });
  }

  requestCreditCard() {
    this.squareService.requestCreditCardModal(
      this.rightClickAppointment.patientId,
      this.rightClickAppointment.appointmentId
    );
  }

  deleteBlockedAppointment() {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '400px',
      data: {
        result: '',
        selectedCancelReason: '',
        customCancelReason: '',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsub),
        mergeMap((result) => {
          if (result === 'delete') {
            return this.appointmentService.removeAppointment(this.rightClickAppointment.appointmentId);
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  // #endregion

  // #region Helper Methods

  isProviderScheduled(providerId: string) {
    return this.workingProviders?.some((p) => p.id == providerId);
  }

  // changeSelectedClinic() {
  //   //Clear-up, close down and reload with new clinic
  //   this.configLoaded = false;
  //   this.appointmentService.closeActionPanel();
  //   if (this.nowIndicatorIntervalID) {
  //     clearInterval(this.nowIndicatorIntervalID);
  //   }
  //   this.unsub.next();
  //   this.unsub.complete();
  //   // this.init();
  // }

  private loadingOperator<T>(loadingMessage: string): MonoTypeOperatorFunction<T> {
    return ((source$: Observable<T>) => {
      return defer(() => {
        this.loadingMessages.push(loadingMessage);
        return source$;
      }).pipe(
        finalize(() => {
          this.loadingMessages = this.loadingMessages.filter((message) => message !== loadingMessage);
        })
      );
    }).bind(this);
  }

  private async checkAppointmentIsLocked(
    appointment: Appointment,
    startTime: Date,
    isResize = false
  ): Promise<boolean> {
    // Check if Appointment is locked
    if (appointment.service.isLocked) {
      const serviceProvider = this.workingProviders.filter((s) => s.id === appointment.staffId);
      const message = `<strong>${appointment.service.serviceName}</strong> at <strong>${moment(startTime).format(
        'h:mm A'
      )}</strong> by <strong>${serviceProvider[0].title}</strong> is locked and ${
        isResize ? 'the duration cannot be changed' : 'cannot be moved.'
      }`;
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Error - Locked Service',
          content: message,
          confirmButtonText: 'Ok',
          showCancel: false,
        },
      });
      await dialogRef.afterClosed().toPromise();
      return true;
    }
    return false;
  }

  private openPatientPanel(appointment: Appointment) {
    this.patientService.getPatientById(appointment.patientId).subscribe((patient) => {
      if (patient) {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate([
          '/schedule',
          { outlets: { 'action-panel': ['patient', patient.patientId + '__patientprofiletab'] } },
        ]);
      }
    });
  }

  private clearAllApptMarksForMove() {
    this.appointmentService.apptsMarkedForMove.clear();
  }

  private clearAllApptSelections() {
    this.appointmentService.apptsSelected.clear();
  }

  // #endregion

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
