export class PaidCancellation {
  description: string;
  purchaseDate: Date;
  pricePaid: number;
  quantity: number = 1;
  financeTransactionId: number;
  invoiceId: number;
  constructor(init?: Partial<PaidCancellation>) {
    Object.assign(this, init);
  }
}
