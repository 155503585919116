<div
  [ngClass]="{
    'calendar-red-background': !type || type == errorMessageType.Error,
    'calendar-blue-background': type == errorMessageType.Info
  }"
  class="error-message d-flex p-3 white-font w-100"
>
  <strong class="d-flex mr-1">
    <ng-container *ngIf="!type || type == errorMessageType.Error">
      <i class="mr-1 far fa-exclamation-triangle"></i>
      ERROR:
    </ng-container>
    <ng-container *ngIf="type == errorMessageType.Info">
      <i class="mr-1 far fa-exclamation-circle"></i>
      INFO:
    </ng-container>
  </strong>
  <div class="text-break" [innerHTML]="message | keepHtml"></div>
  <button *ngIf="showCloseButton" (click)="onClose.emit(null)" class="ml-auto btn-clean white-font">
    <i class="far fa-times"></i>
  </button>
</div>
