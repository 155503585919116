<div [class.isDisabled]="isDisabled" [ngClass]="{ 'text-dark': isDisabled }">
  <ngx-loading [show]="loading"></ngx-loading>
  <mat-label *ngIf="convergeCards?.length > 0" class="label-header teal-font" [class.isDisabled]="isDisabled"
    >Please select a Credit Card on file:</mat-label
  >
  <div class="mb-1">
    <mat-radio-group
      name="selectedCard"
      id="selectedCard"
      [(ngModel)]="selectedConvergeCard"
      (change)="onConvergeCardSelected($event)"
    >
      <ng-container *ngFor="let convergeCard of convergeCards">
        <mat-radio-button
          class="select-card-radio-button card mb-2 p-2 mr-2 grey-four-background"
          [disabled]="compareExpiryToMinimum(convergeCard)"
          [value]="convergeCard"
        >
          <div class="d-flex flex-column">
            <app-converge-card [card]="convergeCard" [showSmallBrandLogo]="true"></app-converge-card>
            <span *ngIf="compareExpiryToMinimum(convergeCard)" class="card-error">Card expires before appointment</span>
          </div>
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>
  <button class="btn em-btn em-btn-green mb-1 float-right" [disabled]="isDisabled" (click)="initiateLightbox()">
    Add New Credit Card
  </button>
  <br />
  <!--   Transaction Token:
  <input class="text-dark disabled-element" [disabled]="true" id="token" type="text" [(ngModel)]="token" name="token" />
  <br /> -->
</div>
