import { SquareCard } from '@models/square/square-card';

export class PaymentHelper {
  static getCreditCardIcon(brand: string) {
    let result = 'far fa-credit-card';
    switch (brand) {
      case 'VISA':
        result = 'fab fa-cc-visa';
        break;
      case 'MC':
      case 'MASTERCARD':
        result = 'fab fa-cc-mastercard';
        break;
      case 'AMEX':
      case 'AMERICAN_EXPRESS':
        result = 'fab fa-cc-amex';
        break;
      case 'DISC':
      case 'DISCOVER':
      case 'DISCOVER_DINERS':
        result = 'fab fa-cc-discover';
        break;
      case 'JCB':
        result = 'fab fa-cc-jcb';
        break;
      case 'INTERAC':
        result = 'far fa-credit-card-front';
        break;
    }
    return result;
  }

  static getCreditCardString(card: SquareCard) {
    if (!card) return 'Credit Card';

    const brand = card.card_brand;
    let result = 'Credit Card';
    switch (brand) {
      case 'VISA':
        result = 'Visa';
        break;
      case 'MASTERCARD':
        result = 'Mastercard';
        break;
      case 'AMERICAN_EXPRESS':
        result = 'American Express';
        break;
      case 'DISCOVER':
      case 'DISCOVER_DINERS':
        result = 'Discover';
        break;
      case 'JCB':
        result = 'JCB';
        break;
      case 'INTERAC':
        result = 'Interac';
        break;
    }
    return result;
  }
}
