import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Patient } from '@models/patient';
import {
  ConnectionState,
  Conversation,
  Client as ConversationsClient,
  Message,
  Participant,
  SendMediaOptions,
} from '@twilio/conversations';
import { countryTuples } from 'country-region-data';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClinicsService } from './clinics.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class TwilioConversationsService {
  conversationMessages = new Map<string, Message[]>();
  conversationAllAutomated = new Map<string, boolean>();
  conversationUnreadCount = new Map<string, number>();
  isTyping = false;
  private clinicId: number;
  private client: ConversationsClient;

  private _initialized = false;
  get initialized() {
    return this._initialized;
  }
  private set initialized(state: boolean) {
    this._initialized = state;
  }

  private _selectedConversation: Conversation;
  get selectedConversation() {
    return this._selectedConversation;
  }
  set selectedConversation(conversation: Conversation) {
    this.isTyping = false;
    this._selectedConversation = conversation;
    if (conversation) {
      setTimeout(
        async () =>
          await conversation.setAllMessagesRead().catch((e) => {
            throw e;
          })
      );
      this.conversationUnreadCount.set(conversation.sid, 0);
    }
    this.selectedConversationUpdated.next(this.selectedConversation);
  }

  private conversations = new BehaviorSubject<Conversation[]>([]);
  conversations$ = this.conversations.asObservable();

  private conversationMessageAdded = new Subject<string>();
  conversationMessageAdded$ = this.conversationMessageAdded.asObservable();

  private connectionState = new BehaviorSubject<ConnectionState>(null);
  connectionState$ = this.connectionState.asObservable();

  private selectedConversationUpdated = new BehaviorSubject<Conversation>(null);
  selectedConversationUpdated$ = this.selectedConversationUpdated.asObservable();

  constructor(private http: HttpClient, private clinicsService: ClinicsService, private usersService: UsersService) {
    this.clinicsService.clinicIdSelected$.subscribe(async (clinicId) => {
      if (this.clinicId != clinicId) {
        this.clinicId = clinicId;
        await this.initConversationsClient();
      }
    });
  }

  private getAccessToken() {
    return this.http.get(environment.baseUrl + 'api/Twilio', { responseType: 'text' });
  }

  private async initConversationsClient() {
    console.log('Patient Messaging - Initializing...');
    this.initialized = false;
    const token = await this.getAccessToken().toPromise();
    this.client = new ConversationsClient(token);
    this.listenToEvents();
  }

  private listenToEvents() {
    this.client.removeAllListeners();

    this.client.on('connectionStateChanged', (state) => {
      console.log(`Patient Messaging State: ${state}`);
      this.connectionState.next(state);
    });

    this.client.on('initFailed', (error: any) => {
      console.log('Patient Messaging - Initializing Failed.');
    });

    this.client.on('connectionError', (error: any) => {
      console.log('Patient Messaging - Connection Error.');
    });

    this.client.on('tokenAboutToExpire', async () => {
      const token = await this.getAccessToken().toPromise();
      this.client = await this.client.updateToken(token).catch((e) => {
        throw e;
      });
    });

    this.client.on('tokenExpired', () => {
      console.log('Patient Messaging - Token Expired, Reinitializing.');
      this.client.removeAllListeners();
      this.initConversationsClient();
    });

    this.client.on('initialized', this.onInitialized);

    this.client.on('conversationLeft', (conversation) => {
      this.conversations.next(this.conversations.value.filter((con) => con.sid != conversation.sid));
    });

    this.client.on('conversationJoined', async (conversation) => {
      if (this.initialized) {
        await conversation.setAllMessagesUnread().catch((e) => {
          throw e;
        });
        await this.setConversationMaps(conversation);
        this.conversations.next([conversation, ...this.conversations.value]);
      }
    });

    this.client.on('messageAdded', this.onMessageAdded);

    this.client.on('typingStarted', (user: Participant) => {
      if (user.conversation.sid === this.selectedConversation?.sid) this.isTyping = true;
    });

    this.client.on('typingEnded', (user: Participant) => {
      if (user.conversation.sid === this.selectedConversation?.sid) this.isTyping = false;
    });
  }

  //  #region Event Callbacks
  private onInitialized = async () => {
    this.selectedConversation = null;
    await this.getClinicConversations();
    this.initialized = true;
    console.log('Patient Messaging - Initialized.');
  };

  private onMessageAdded = async (message: Message) => {
    const messageConversation = message.conversation;
    const messageConSid = message.conversation.sid;
    const existingConMessages = this.conversationMessages.get(messageConSid) ?? [];
    const existingAllAutomated =
      existingConMessages.length === 0 ? true : this.conversationAllAutomated.get(messageConSid);
    const messageAutomated = (message.attributes as any)?.automated ?? false;
    const allAutomated = !existingAllAutomated ? false : messageAutomated;

    this.conversationAllAutomated.set(messageConSid, allAutomated);
    this.conversationMessages.set(messageConSid, [...existingConMessages, message]);

    if (this.selectedConversation?.sid === message.conversation.sid) {
      await this.selectedConversation?.updateLastReadMessageIndex(message.index).catch((e) => {
        throw e;
      });
    } else {
      const unreadCount = await message.conversation.getUnreadMessagesCount().catch((e) => {
        throw e;
      });
      this.conversationUnreadCount.set(messageConSid, unreadCount);
    }

    const archived = this.getConversationArchiveStatus(messageConversation);
    if (archived) {
      await this.setConversationArchiveStatus(messageConversation, false);
    }

    //update conversation for sorting
    let conversations = this.conversations.value;
    const conIndex = conversations.findIndex((con) => con.sid == messageConversation.sid);
    if (conIndex === -1) {
      // Conversations out of date
      await this.getClinicConversations();
    } else {
      conversations[conIndex] = messageConversation;
      conversations = this.sortConversations(conversations);
      this.conversations.next(conversations);
    }
    this.conversationMessageAdded.next(messageConSid);
  };
  // #endregion

  // #region Public Actions
  async selectPatientConversation(patient: Patient) {
    try {
      const existingConversation = await this.getPatientConversation(patient.patientId);
      if (existingConversation) {
        if (this.getConversationArchiveStatus(existingConversation)) {
          await this.setConversationArchiveStatus(existingConversation, false);
        }
        this.selectedConversation = existingConversation;
      } else {
        await this.addPatientConversation(patient);
      }
    } catch (error) {
      throw error;
    }
  }

  async sendMessage(message: string, image?: File): Promise<void> {
    if (this.selectedConversation) {
      const messageBuilder = this.selectedConversation
        .prepareMessage()
        .setBody(message)
        .setAttributes({
          automated: false,
          user: {
            id: this.usersService.loggedInUser.id,
            fullName: this.usersService.loggedInUser.fullName,
            firstName: this.usersService.loggedInUser.firstName,
            lastName: this.usersService.loggedInUser.lastName,
            avatar: this.usersService.loggedInUser.avatar.split('?')[0],
          },
        });
      if (image) {
        const sendMediaOptions: SendMediaOptions = {
          contentType: image.type,
          filename: image.name,
          media: image,
        };
        messageBuilder.addMedia(sendMediaOptions);
      }

      await messageBuilder.build().send();
    }
  }

  getLatestMessage(conversation: Conversation): Message {
    // const lastMessageIndex = conversation.lastMessage?.index;
    const conMessages = this.conversationMessages.get(conversation.sid);
    if (!conMessages || !conMessages.length) {
      return null;
    }
    return conMessages[conMessages.length - 1];
  }

  getUnreadCount(conversation: Conversation): number {
    return this.conversationUnreadCount.get(conversation.sid);
  }

  getTotalUnreadCount(): number {
    let total = 0;
    for (const count of this.conversationUnreadCount.values()) {
      total += count;
    }
    return total;
  }

  getClientUserIdentity() {
    return this.client.user.identity;
  }

  getConversationArchiveStatus(conversation: Conversation): boolean {
    return (conversation.attributes as any).archived ?? false;
  }

  parsePatientId(conversation: Conversation): number {
    const patientPart = conversation.uniqueName?.split('-')[1];
    const patientId = patientPart?.split('_')[1];
    return Number(patientId);
  }

  async setConversationArchiveStatus(conversation: Conversation, status: boolean) {
    const attributes = conversation.attributes as any;
    attributes.archived = status;
    await conversation.updateAttributes(attributes).catch((e) => {
      throw e;
    });
  }

  // #endregion

  // #region Private Helpers
  private async addPatientConversation(patient: Patient) {
    if (!patient) throw new Error('No patient provided for conversation.');
    if (!patient.mobileNumber) throw new Error('Patient does not have a mobile number set.');
    if (!patient.address?.country) throw new Error('Patient does not have a country set.');
    if (!this.clinicsService.clinic?.twilioFromNumber) throw new Error('Clinic does not have a from number set.');
    const clinicNumber = this.clinicsService.clinic?.twilioFromNumber;
    const patientName = patient.firstName + ' ' + (patient.nickName ? `"${patient.nickName}" ` : '') + patient.lastName;
    const countryCode = countryTuples.find(
      (value) => value[0].toLowerCase() === patient.address.country.toLowerCase()
    )[1];
    const formattedNumber = parsePhoneNumber(patient.mobileNumber, countryCode as CountryCode);

    let newConversation;
    try {
      newConversation = await this.client.createConversation({
        friendlyName: patientName,
        uniqueName: this.generateUniqueName(patient.patientId),
      });
      await newConversation.join();
      await newConversation.addNonChatParticipant(clinicNumber, formattedNumber.number);
      this.selectedConversation = newConversation;
    } catch (error) {
      if (newConversation) {
        try {
          await newConversation.delete();
        } catch (error) {
          // Conversation not found
          if (error.body?.code !== 50350) throw error;
        }
      }
      throw error;
    }
  }

  private async setConversationMaps(conversation) {
    const unreadCount =
      (await conversation.getUnreadMessagesCount().catch((e) => {
        throw e;
      })) ?? 0;
    const messages = await this.getConversationMessages(conversation);
    const allAutomated =
      messages.length === 0 ? false : messages.every((message) => (message.attributes as any).automated ?? false);
    this.conversationUnreadCount.set(conversation.sid, unreadCount);
    this.conversationMessages.set(conversation.sid, messages);
    this.conversationAllAutomated.set(conversation.sid, allAutomated);
  }

  private async getConversationMessages(conversation: Conversation): Promise<Message[]> {
    let messagesPage = await conversation.getMessages().catch((e) => {
      throw e;
    });
    const messages = messagesPage.items;
    while (messagesPage.hasPrevPage) {
      messagesPage = await messagesPage.prevPage().catch((e) => {
        throw e;
      });
      messages.unshift(...messagesPage.items);
    }
    return messages;
  }

  private async getPatientConversation(patientId: number) {
    if (this.client.connectionState !== 'connected') throw new Error('Not connected to Twilio');
    const uniqueName = this.generateUniqueName(patientId);
    try {
      return await this.client.getConversationByUniqueName(uniqueName);
    } catch (error) {
      // Conversation not found
      if (error.body?.code === 50350) {
        return null;
      } else {
        throw error;
      }
    }
  }

  private async getClinicConversations() {
    this.conversationUnreadCount.clear();
    this.conversationMessages.clear();
    this.conversationAllAutomated.clear();
    let conversationsPage = await this.client.getSubscribedConversations().catch((e) => {
      throw e;
    });
    let conversations = conversationsPage.items;
    while (conversationsPage.hasNextPage) {
      conversationsPage = await conversationsPage.nextPage().catch((e) => {
        throw e;
      });
      conversations.push(...conversationsPage.items);
    }
    await Promise.all(conversations.map((conversation) => this.setConversationMaps(conversation)));
    conversations = this.sortConversations(conversations);
    this.conversations.next(conversations);
  }

  private sortConversations(conversations: Conversation[]): Conversation[] {
    return conversations.sort((a, b) => {
      let aComp = a.lastMessage ? a.lastMessage.dateCreated.getTime() : a.dateCreated.getTime();
      let bComp = b.lastMessage ? b.lastMessage.dateCreated.getTime() : b.dateCreated.getTime();
      return bComp - aComp;
    });
  }

  private generateUniqueName(patientId: number) {
    return `${this.client.user.identity}-patient_${patientId}`;
  }

  // #endregion
}
