<div class="d-inline-flex flex-column card-info">
  <div>
    <div *ngIf="!showSmallBrandLogo" class="mb-1">
      <i class="fa-3x fab" [class]="PaymentHelper.getCreditCardIcon(card.brand)"></i>
    </div>
    {{ card.brand }} {{ card?.maskedDigits }}
    <div>
      <i *ngIf="showSmallBrandLogo" [class]="PaymentHelper.getCreditCardIcon(card.brand)"></i> Expires
      {{ card?.expiry }}
    </div>
  </div>
</div>
